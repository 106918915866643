import { Box, Button } from '@chakra-ui/react';

type DocumentActionsProps = {
  onDownload: () => void;
  onApprove: () => void;
  onReject: () => void;
  isApproveDisabled: boolean;
  isRejectDisabled: boolean;
};

export const DocumentActions = ({
  onDownload,
  onApprove,
  onReject,
  isApproveDisabled,
  isRejectDisabled,
}: DocumentActionsProps) => {
  return (
    <>
      <Button
        fontSize="16px"
        bgColor={'white'}
        color="#0076B3"
        textDecoration="underline"
        cursor="pointer"
        onClick={onDownload}
      >
        Download
      </Button>
      <Box height="16px" width="1px" backgroundColor="#E2E8F0" mx="8px" />
      <Button
        fontSize="16px"
        bgColor={'white'}
        color="#EE5D50"
        textDecoration="underline"
        cursor="pointer"
        isDisabled={isRejectDisabled}
        onClick={onReject}
      >
        Reject
      </Button>
      <Box height="16px" width="1px" backgroundColor="#E2E8F0" mx="8px" />
      <Button
        fontSize="16px"
        bgColor={'white'}
        color="#05CD99"
        textDecoration="underline"
        cursor="pointer"
        isDisabled={isApproveDisabled}
        onClick={onApprove}
      >
        Approve
      </Button>
    </>
  );
};
