import React, { useEffect, useRef, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Button,
  Flex,
  Box,
  Input,
  FormControl,
  FormLabel,
  Tbody,
  Table,
  Td,
  Tr,
  Th,
  Thead,
  Heading,
  FormErrorMessage,
  SimpleGrid,
  Link,
  Switch,
} from '@chakra-ui/react';
import { useFetchTenantById, useUpdateTenant } from '../hooks/useTenant';
import { CreateUser, User } from '../types/User';
import { useCreateUser, useFetchUsersByTenantId } from '../hooks/useUser';
import StaffModal from './Staff/StaffModal';
import { SubmitHandler, useForm } from 'react-hook-form';
import UserDetailModal from './UserDetailModal';
import { useAuth } from 'react-oidc-context';

interface TenantAdminModalProps {
  isOpen: boolean;
  onClose: () => void;
  tenantId: number;
}

const TenantAdminModal: React.FC<TenantAdminModalProps> = ({
  isOpen,
  onClose,
  tenantId,
}) => {
  const { data: userData } = useFetchUsersByTenantId(tenantId ?? '');
  const { data: tenantData } = useFetchTenantById(tenantId ?? '');
  const { mutateAsync: createUser } = useCreateUser();
  const [isAddStaffOpen, setAddStaffOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isUserDetailPopupOpen, setUserDetailPopupOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState<User[]>([]);
  const [isSearchInputFocused, setIsSearchInputFocused] = useState(false);
  console.log(userData, 'abc');
  const updateTenantMutations = useUpdateTenant();

  const auth = useAuth();
  const tenant_type = auth.user?.profile?.tenant_type as string;

  const handleUserRowClick = (user: User) => {
    if (!isSearchInputFocused) {
      setSelectedUser(user);
      setUserDetailPopupOpen(true);
    }
  };

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchTerm(event.target.value);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      tenantName: tenantData?.tenantName || '',
      tenantPhone: tenantData?.tenantPhone || '',
      tenantContactEmail: tenantData?.tenantContactEmail || '',
      tenantContactName: tenantData?.tenantContactName || '',
      tenantDomain: tenantData?.tenantDomain || '',
      tenantAddress: tenantData?.tenantAddress || '',
      tenantCity: tenantData?.tenantCity || '',
      tenantState: tenantData?.tenantState || '',
      tenantZip: tenantData?.tenantZip || '',
    },
  });

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    const modifiedData = {
      ...data,
      tenantLogoPath: tenantData?.tenantLogoPath,
      tenantLicensingNumber: tenantData?.tenantLicensingNumber,
      tenantRegistration: tenantData?.tenantRegistration,
      tenantTypeId: tenantData?.tenantTypeId,
    };

    try {
      await updateTenantMutations.mutateAsync({
        tenantId: tenantId ?? '',
        tenantData: { ...modifiedData },
      });
    } catch (error) {
      console.error('Error updating tenant:', error);
    }
  };

  const handleModalClose = () => {
    onClose();
    if (tenantData) {
      reset({
        tenantName: tenantData.tenantName,
        tenantPhone: tenantData.tenantPhone,
        tenantContactEmail: tenantData.tenantContactEmail,
        tenantContactName: tenantData.tenantContactName,
        tenantDomain: tenantData.tenantDomain,
        tenantAddress: tenantData.tenantAddress,
        tenantCity: tenantData.tenantCity,
        tenantState: tenantData.tenantState,
        tenantZip: tenantData?.tenantZip || '',
      });
    }
  };

  const handleAddStaffOpen = () => {
    setAddStaffOpen(true);
  };

  const handleAddStaffClose = () => {
    setAddStaffOpen(false);
  };

  const handleAddStaffSubmit = async (values: CreateUser) => {
    await createUser(
      { userData: values },
      {
        onSuccess: () => {
          console.log('User created successfully');
          handleAddStaffClose();
        },
      },
    );

    setAddStaffOpen(false);
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredData.slice(
    indexOfFirstRecord,
    indexOfLastRecord,
  );

  const totalPages = Math.ceil(filteredData.length / recordsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handlePageClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <Button
          key={i}
          onClick={() => handlePageClick(i)}
          variant={currentPage === i ? 'solid' : 'outline'}
        >
          {i}
        </Button>,
      );
    }
    return pageNumbers;
  };
  useEffect(() => {
    // if (selectedUser) {
    //   setUserDetailPopupOpen(true);
    // }
    if (tenantData) {
      reset({
        tenantName: tenantData.tenantName,
        tenantPhone: tenantData.tenantPhone,
        tenantContactEmail: tenantData.tenantContactEmail,
        tenantContactName: tenantData.tenantContactName,
        tenantDomain: tenantData.tenantDomain,
        tenantAddress: tenantData.tenantAddress,
        tenantCity: tenantData.tenantCity,
        tenantState: tenantData.tenantState,
        tenantZip: tenantData.tenantZip,
      });
    }
    if (searchTerm.trim() === '') {
      setFilteredData(userData ?? []);
    } else {
      const lowercasedFilter = searchTerm.toLowerCase();
      const filtered = (userData ?? []).filter((user: any) =>
        Object.keys(user).some(
          (key) =>
            key !== 'phoneNumber' &&
            user[key as keyof User]
              ?.toString()
              .toLowerCase()
              .includes(lowercasedFilter),
        ),
      );
      setFilteredData(filtered);
    }
    setCurrentPage(1);
  }, [selectedUser, searchTerm, userData, tenantData, reset]);
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<string | number>('auto');

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [contentRef.current]);
  const updateUser = async (userId: string, isActive: number) => {
    // try {
    //   await updateUserMutation({
    //     userId: userId,
    //     isActive: isActive,
    //   });
    // } catch (error) {
    //   console.error('Error updating user:', error);
    // }
  };

  return userData && tenantData ? (
    <>
      <Modal isOpen={isOpen} onClose={handleModalClose} size="lg">
        <ModalOverlay />
        <ModalContent sx={{ maxWidth: '90vw', height: contentHeight }}>
          <Box padding={4} ref={contentRef}>
            <Flex
              align="center"
              justify="space-between"
              width="100%"
              padding={4}
            >
              <Heading size="lg" fontFamily="DM Sans Variable">
                Tenant
              </Heading>
            </Flex>
            <ModalCloseButton />
            <ModalBody>
              <Tabs variant="enclosed">
                <TabList>
                  <Tab fontFamily="DM Sans Variable">Info</Tab>
                  <Tab fontFamily="DM Sans Variable">Staff</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Box>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <Box paddingRight="20px">
                          <Flex justify="space-between" align="center" mb={4}>
                            <Heading size="md" fontFamily="DM Sans Variable">
                              Tenant Profile
                            </Heading>
                          </Flex>

                          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
                            <Box>
                              <FormControl isInvalid={!!errors.tenantName}>
                                <FormLabel
                                  width="150px"
                                  fontFamily="DM Sans Variable"
                                >
                                  Name
                                </FormLabel>
                                <Input
                                  placeholder="Company Name"
                                  fontFamily="DM Sans Variable"
                                  {...register('tenantName', {
                                    required: 'Company Name is required',
                                  })}
                                />
                                <FormErrorMessage>
                                  {errors.tenantName &&
                                    errors.tenantName.message}
                                </FormErrorMessage>
                              </FormControl>
                            </Box>
                            <Box>
                              <FormControl isInvalid={!!errors.tenantPhone}>
                                <FormLabel
                                  width="150px"
                                  fontFamily="DM Sans Variable"
                                >
                                  Phone
                                </FormLabel>
                                <Input
                                  placeholder="Mobile Number"
                                  fontFamily="DM Sans Variable"
                                  type="tel"
                                  {...register('tenantPhone', {
                                    required: 'Mobile Number is required',
                                    minLength: {
                                      value: 10,
                                      message: 'Length should be 10',
                                    },
                                    maxLength: {
                                      value: 10,
                                      message: 'Length should be 10',
                                    },
                                  })}
                                />
                                <FormErrorMessage>
                                  {errors.tenantPhone &&
                                    errors.tenantPhone.message}
                                </FormErrorMessage>
                              </FormControl>
                            </Box>
                            <Box>
                              <FormControl
                                isInvalid={!!errors.tenantContactEmail}
                              >
                                <FormLabel
                                  width="150px"
                                  fontFamily="DM Sans Variable"
                                >
                                  Contact Email
                                </FormLabel>
                                <Input
                                  placeholder="Email"
                                  fontFamily="DM Sans Variable"
                                  type="email"
                                  {...register('tenantContactEmail', {
                                    required: 'Email is required',
                                    pattern: {
                                      value:
                                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                      message: 'Invalid email address',
                                    },
                                  })}
                                />
                                <FormErrorMessage>
                                  {errors.tenantContactEmail &&
                                    errors.tenantContactEmail.message}
                                </FormErrorMessage>
                              </FormControl>
                            </Box>
                            <Box>
                              <FormControl
                                isInvalid={!!errors.tenantContactName}
                              >
                                <FormLabel
                                  width="150px"
                                  fontFamily="DM Sans Variable"
                                >
                                  Contact Name
                                </FormLabel>
                                <Input
                                  placeholder="Contact Name"
                                  fontFamily="DM Sans Variable"
                                  {...register('tenantContactName', {
                                    required: 'Contact Name is required',
                                  })}
                                />
                                <FormErrorMessage>
                                  {errors.tenantContactName &&
                                    errors.tenantContactName.message}
                                </FormErrorMessage>
                              </FormControl>
                            </Box>
                            <Box>
                              <FormControl isInvalid={!!errors.tenantDomain}>
                                <FormLabel
                                  width="150px"
                                  fontFamily="DM Sans Variable"
                                >
                                  Domain
                                </FormLabel>
                                <Input
                                  placeholder="Website"
                                  fontFamily="DM Sans Variable"
                                  {...register('tenantDomain', {
                                    required: 'Domain is required',
                                  })}
                                />
                                <FormErrorMessage>
                                  {errors.tenantDomain &&
                                    errors.tenantDomain.message}
                                </FormErrorMessage>
                              </FormControl>
                            </Box>
                            <Box>
                              <FormControl isInvalid={!!errors.tenantAddress}>
                                <FormLabel
                                  width="150px"
                                  fontFamily="DM Sans Variable"
                                >
                                  Address
                                </FormLabel>
                                <Input
                                  placeholder="Address"
                                  fontFamily="DM Sans Variable"
                                  {...register('tenantAddress', {
                                    required: 'Address is required',
                                  })}
                                />
                                <FormErrorMessage>
                                  {errors.tenantAddress &&
                                    errors.tenantAddress.message}
                                </FormErrorMessage>
                              </FormControl>
                            </Box>
                            <Box>
                              <FormControl
                                isInvalid={!!errors.tenantCity}
                                marginTop="1rem"
                              >
                                <FormLabel
                                  width="150px"
                                  fontFamily="DM Sans Variable"
                                >
                                  City
                                </FormLabel>
                                <Input
                                  placeholder="City"
                                  fontFamily="DM Sans Variable"
                                  {...register('tenantCity', {
                                    required: 'City is required',
                                  })}
                                />
                                <FormErrorMessage>
                                  {errors.tenantCity &&
                                    errors.tenantCity.message}
                                </FormErrorMessage>
                              </FormControl>
                            </Box>
                            <Box>
                              <FormControl
                                isInvalid={!!errors.tenantZip}
                                marginTop="1rem"
                              >
                                <FormLabel
                                  width="150px"
                                  fontFamily="DM Sans Variable"
                                >
                                  Zip
                                </FormLabel>
                                <Input
                                  placeholder="Zip"
                                  fontFamily="DM Sans Variable"
                                  {...register('tenantZip', {
                                    required: 'Zip is required',
                                  })}
                                />
                                <FormErrorMessage>
                                  {errors.tenantZip && errors.tenantZip.message}
                                </FormErrorMessage>
                              </FormControl>
                            </Box>
                            <Box>
                              <FormControl
                                isInvalid={!!errors.tenantState}
                                marginTop="1rem"
                              >
                                <FormLabel
                                  width="150px"
                                  fontFamily="DM Sans Variable"
                                >
                                  State
                                </FormLabel>
                                <Input
                                  placeholder="State"
                                  fontFamily="DM Sans Variable"
                                  {...register('tenantState', {
                                    required: 'State is required',
                                  })}
                                />
                                <FormErrorMessage>
                                  {errors.tenantState &&
                                    errors.tenantState.message}
                                </FormErrorMessage>
                              </FormControl>
                            </Box>
                          </SimpleGrid>

                          <Flex justifyContent="flex-end" mt={4} gap={4}>
                            <Button
                              type="submit"
                              colorScheme="green"
                              isLoading={isSubmitting}
                              fontFamily="DM Sans Variable"
                            >
                              Save
                            </Button>
                            <Button
                              onClick={() => reset()}
                              colorScheme="red"
                              fontFamily="DM Sans Variable"
                            >
                              Cancel
                            </Button>
                          </Flex>
                        </Box>
                      </form>
                    </Box>
                  </TabPanel>

                  <TabPanel>
                    <Box
                      sx={{
                        height: '55vh',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Flex justify="space-between" align="center" mb={2}>
                        <Heading size="md">Staff Management</Heading>
                        <Button colorScheme="blue" onClick={handleAddStaffOpen}>
                          Add Staff
                        </Button>
                      </Flex>
                      <Flex justify="space-between" align="center" mb={2}>
                        <Input
                          width="200px"
                          placeholder="Search"
                          value={searchTerm}
                          onChange={handleSearchInputChange}
                          onFocus={() => setIsSearchInputFocused(true)}
                          onBlur={() => setIsSearchInputFocused(false)}
                        />
                        <Flex gap={2}>
                          <Button
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                            variant={currentPage === 1 ? 'outline' : 'solid'}
                            colorScheme="blue"
                          >
                            Previous
                          </Button>
                          {renderPageNumbers()}
                          <Button
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                            variant={
                              currentPage === totalPages ? 'outline' : 'solid'
                            }
                            colorScheme="blue"
                          >
                            Next
                          </Button>
                        </Flex>
                      </Flex>
                      {filteredData.length === 0 ? (
                        <Box
                          textAlign="center"
                          p={4}
                          fontFamily={'DM Sans Variable'}
                        >
                          No records found.
                        </Box>
                      ) : (
                        <Box flex="1">
                          <Table variant="simple">
                            <Thead fontFamily={'DM Sans Variable'}>
                              <Tr>
                                <Th>Email</Th>
                                <Th>FirstName</Th>
                                <Th>LastName</Th>
                                <Th>Role</Th>
                                <Th>Phone</Th>
                                <Th>Status</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {currentRecords.map((user: User) => (
                                <Tr key={user.userId}>
                                  <Td>
                                    <Link
                                      color="blue.500"
                                      onClick={() => {
                                        handleUserRowClick(user);
                                      }}
                                    >
                                      {user.email}
                                    </Link>
                                  </Td>
                                  <Td>{user.firstName}</Td>
                                  <Td>{user.lastName}</Td>
                                  <Td></Td>
                                  <Td>{user.phoneNumber}</Td>
                                  <Td>
                                    <Flex align="center">
                                      <Switch
                                        size="sm"
                                        isChecked={user.isActive === 1}
                                        onChange={() => {
                                          const newIsActive =
                                            user.isActive === 1 ? 0 : 1;
                                          updateUser(user.userId, newIsActive); // Pass userId and newIsActive
                                        }}
                                      />
                                      <Box marginLeft={2}>
                                        {user.isActive ? 'Active' : 'Inactive'}
                                      </Box>
                                    </Flex>
                                  </Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>
                        </Box>
                      )}
                    </Box>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </ModalBody>
          </Box>
        </ModalContent>
      </Modal>

      {selectedUser && (
        <UserDetailModal
          isOpen={isUserDetailPopupOpen}
          onClose={() => setUserDetailPopupOpen(false)}
          userData={selectedUser}
        />
      )}

      <StaffModal
        isOpen={isAddStaffOpen}
        onClose={handleAddStaffClose}
        onStaffSubmit={(values) => handleAddStaffSubmit(values)}
        userData={{
          email: '',
          firstName: '',
          lastName: '',
          phoneNumber: '',
          tenantId: tenantId,
          role: {
            isAdminUser: 0,
            tenantType: tenant_type,
          },
        }}
      />
    </>
  ) : null;
};

export default TenantAdminModal;
