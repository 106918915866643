import { createContext, useContext } from 'react';
import { User } from '../types/User';

const UserContext = createContext<User | undefined>(undefined);

// export const UserProvider: React.FC<{ children: ReactNode }> = ({
//   children,
// }) => {
//   const auth = useAuth();
//   const userId = auth.user?.profile?.sub;
//   const { data: user } = useFetchUser(userId ?? '');

//   return (
//     <UserContext.Provider value={user}>{children}</UserContext.Provider>
//   );
// };

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext was used outside of its Provider');
  }
  return context;
};

export default UserContext;
