
import { axiosClient } from "../config/axiosClient";

export const resetPasswordById = async (userId: string) => {
    const url = `/MailDelivery/SendPasswordResetEmail?userId=${userId}`;
    const response = await axiosClient.put(url, {}, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};
