import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { fetchCities, fetchCitiesByTenantId, fetchDocumentStatuses, fetchStaticData, fetchStatusTransitions, updateTenantIdForCities } from "../services/api/staticDataService";
import { AxiosError } from "axios";


export const useFetchStaticData = () => {
    return useQuery({
        queryKey: ["getStaticData"],
        queryFn: fetchStaticData,
        staleTime: 300000,
    });
};

export const useFetchDocumentStatuses = () => {
    return useQuery({
        queryKey: ["getDocumentStatuses"],
        queryFn: fetchDocumentStatuses,
        staleTime: 300000,
    });
};

export const useFetchStatusTransitions = () => {
    return useQuery({
        queryKey: ["getStatusTransitions"],
        queryFn: fetchStatusTransitions,
        staleTime: 300000,
    });
};

export const useFetchCities = () => {
    return useQuery({
        queryKey: ["getCities"],
        queryFn: fetchCities,
    });
};

export const useFetchCitiesByTenantId = (tenantId: number | null) => {
    return useQuery({
        queryKey: ["getCitiesByTenantId", { tenantId }],
        queryFn: () => fetchCitiesByTenantId(tenantId),
        throwOnError(error: AxiosError, query) {
            if (error.response?.status === 404) {
                return false;
            }
            throw error;
        },
    });
};

export const useUpdateTenantIdForCities = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ cityIds, tenantId }: { cityIds: number[], tenantId: number }) => updateTenantIdForCities(cityIds, tenantId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["getCitiesByTenantId"] });
            queryClient.invalidateQueries({ queryKey: ["getCities"] });
            queryClient.invalidateQueries({ queryKey: ["getStaticData"] });
        },
    });
};