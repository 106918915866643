import { Box, Button } from '@chakra-ui/react';

type DocumentActionsProps = {
  onDownload: () => void;
  onRemoveDocument: () => void;
  onUploadNewFile: () => void;
  isRemovedDisabled: boolean;
  isUploadNewFileDisabled: boolean;
  // isShowUploadNewFile?: boolean;
};

export const DocumentActions = ({
  onDownload,
  onRemoveDocument,
  onUploadNewFile,
  isRemovedDisabled,
  isUploadNewFileDisabled,
  // isShowUploadNewFile = true,
}: DocumentActionsProps) => {
  return (
    <>
      <Button
        fontSize="16px"
        bgColor={'white'}
        color="#0076B3"
        textDecoration="underline"
        cursor="pointer"
        onClick={onDownload}
      >
        Download
      </Button>
      <Box height="16px" width="1px" backgroundColor="#E2E8F0" mx="8px" />
      <Button
        fontSize="16px"
        bgColor={'white'}
        color="#0076B3"
        textDecoration="underline"
        cursor="pointer"
        isDisabled={isRemovedDisabled}
        onClick={onRemoveDocument}
      >
        Remove
      </Button>
          <Box height="16px" width="1px" backgroundColor="#E2E8F0" mx="8px" />
          <Button
            fontSize="16px"
            bgColor={'white'}
            color="#0076B3"
            textDecoration="underline"
            cursor="pointer"
            isDisabled={isUploadNewFileDisabled}
            onClick={onUploadNewFile}
          >
            Upload New File
          </Button>
    </>
  );
};
