import { CloseIcon } from '@chakra-ui/icons';
import { Box, Button, Input, Text } from '@chakra-ui/react';
import { useState } from 'react';

type DropzoneProps = {
  onFileChange: (e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>) => void;
  onRemoveFile: () => void;
  dropFile: File | null;
};

export const Dropzone = ({
  onFileChange,
  onRemoveFile,
  dropFile,
}: DropzoneProps) => {
    const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      onFileChange(e);
      e.dataTransfer.clearData();
    }
  };

  return (
    <>
      <Box
        className="dropzone"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        p={4}
        borderWidth="2px"
        borderStyle="dashed"
        borderColor={isDragging ? 'blue.300' : 'gray.300'}
        borderRadius="lg"
        cursor="pointer"
        _hover={{ bg: 'gray.100' }}
        onClick={() => {
          document.getElementById('fileInput')?.click();
        }}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        mt={4}
      >
        <Input
          id="fileInput"
          type="file"
          style={{ display: 'none' }}
          onChange={onFileChange}
          accept="application/pdf"
        />
        <Text
          mx="auto"
          mb="12px"
          fontSize="lg"
          fontWeight="700"
          whiteSpace="pre-wrap"
        >
          Drop your files here, or{' '}
          <Text as="span" fontSize="lg" fontWeight="700">
            browse
          </Text>
        </Text>
        <Text fontSize="sm" fontWeight="500" color="gray.500">
          PDF files are allowed
        </Text>
      </Box>
      {dropFile && (
        <Box mt={4} display="flex" alignItems="center">
          <Text>{dropFile.name}</Text>
          <Button
            aria-label="Remove file"
            variant="ghost"
            color="red.500"
            onClick={onRemoveFile}
            size="sm"
            ml={2}
          >
            <CloseIcon />
          </Button>
        </Box>
      )}
    </>
  );
};
