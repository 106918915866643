import React, { useState } from 'react';
import {
  Box,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
  FormErrorMessage,
  Select,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { CreateTenant, Tenant } from '../../types/Tenant';
import { Select as ChakraSelect } from 'chakra-react-select';
import { useFetchCitiesByTenantId } from '../../hooks/useStaticData';
import { City } from '../../types/City';

interface TenantInfoProps {
  tenantData?: Tenant;
  tenantCities?: City[];
  onClickSave: ({
    values,
    selectedCityIds,
  }: {
    values: CreateTenant;
    selectedCityIds: number[];
  }) => void;
  onCancel: () => void;
  isCreate?: boolean;
  isDisabledCities?: boolean;
}

const TenantInfo: React.FC<TenantInfoProps> = ({
  tenantData,
  tenantCities,
  onClickSave,
  onCancel,
  isCreate = false,
  isDisabledCities = true,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<CreateTenant>({
    defaultValues: {
      tenantName: tenantData?.tenantName,
      tenantDomain: tenantData?.tenantDomain,
      tenantLogoPath: tenantData?.tenantLogoPath,
      tenantAddress: tenantData?.tenantAddress,
      tenantCity: tenantData?.tenantCity,
      tenantState: tenantData?.tenantState,
      tenantZip: tenantData?.tenantZip,
      tenantLicensingNumber: tenantData?.tenantLicensingNumber,
      tenantRegistration: tenantData?.tenantRegistration,
      tenantPhone: tenantData?.tenantPhone,
      tenantContactName: tenantData?.tenantContactName,
      tenantContactEmail: tenantData?.tenantContactEmail,
      tenantTypeId: tenantData?.tenantTypeId,
    },
  });
  const { data: unattachedCities} = useFetchCitiesByTenantId(null);
  const [selectedOptions, setSelectedOptions] = useState<
    { value: number; label: string }[]
  >([]);
  const [tenantType, setTenantType] = useState<number>(
    tenantData?.tenantTypeId ?? 0,
  );

  // console.log(tenantData?.tenantTypeId, 'tenantTypeId');

  const onCancelClick = () => {
    reset();
    onCancel();
  };

  const handleCityChange = (options: any) => {
    setSelectedOptions(options || []);
  };

  const handleTenantTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setTenantType(parseInt(event.target.value));
  };
  function onSave(data: CreateTenant) {
    onClickSave({
      values: data,
      selectedCityIds: selectedOptions.map((option) => option.value),
    });
  }

  return (
    <Flex justifyContent="left">
      <Box width={'80%'} paddingX={[4, 8]}>
        <form onSubmit={handleSubmit(onSave)}>
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            <GridItem colSpan={1}>
              <FormControl isRequired isInvalid={!!errors.tenantName}>
                <FormLabel>Tenant Name</FormLabel>
                <Input {...register('tenantName')} bg="white" />
                <FormErrorMessage>
                  {!!errors.tenantName && errors.tenantName.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl isRequired isInvalid={!!errors.tenantDomain}>
                <FormLabel>Tenant Domain</FormLabel>
                <Input {...register('tenantDomain')} bg="white" />
                <FormErrorMessage>
                  {!!errors.tenantDomain && errors.tenantDomain.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl isInvalid={!!errors.tenantLogoPath}>
                <FormLabel>Tenant Logo Path</FormLabel>
                <Input {...register('tenantLogoPath')} bg="white" />
                <FormErrorMessage>
                  {!!errors.tenantLogoPath && errors.tenantLogoPath.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl isRequired isInvalid={!!errors.tenantAddress}>
                <FormLabel>Tenant Address</FormLabel>
                <Input {...register('tenantAddress')} bg="white" />
                <FormErrorMessage>
                  {!!errors.tenantAddress && errors.tenantAddress.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl isRequired isInvalid={!!errors.tenantCity}>
                <FormLabel>Tenant City</FormLabel>
                <Input {...register('tenantCity')} bg="white" />
                <FormErrorMessage>
                  {!!errors.tenantCity && errors.tenantCity.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl isRequired isInvalid={!!errors.tenantState}>
                <FormLabel>Tenant State</FormLabel>
                <Input {...register('tenantState')} bg="white" />
                <FormErrorMessage>
                  {!!errors.tenantState && errors.tenantState.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl isRequired isInvalid={!!errors.tenantZip}>
                <FormLabel>Tenant Zip</FormLabel>
                <Input {...register('tenantZip')} bg="white" />
                <FormErrorMessage>
                  {!!errors.tenantZip && errors.tenantZip.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl
                isRequired
                isInvalid={!!errors.tenantLicensingNumber}
              >
                <FormLabel>Tenant Licensing Number</FormLabel>
                <Input {...register('tenantLicensingNumber')} bg="white" />
                <FormErrorMessage>
                  {!!errors.tenantLicensingNumber &&
                    errors.tenantLicensingNumber.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl isRequired isInvalid={!!errors.tenantRegistration}>
                <FormLabel>Tenant Registration</FormLabel>
                <Input {...register('tenantRegistration')} bg="white" />
                <FormErrorMessage>
                  {!!errors.tenantRegistration &&
                    errors.tenantRegistration.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl isRequired isInvalid={!!errors.tenantPhone}>
                <FormLabel>Tenant Phone</FormLabel>
                <Input type="tel" {...register('tenantPhone')} bg="white" />
                <FormErrorMessage>
                  {!!errors.tenantPhone && errors.tenantPhone.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl isRequired isInvalid={!!errors.tenantContactName}>
                <FormLabel>Tenant Contact Name</FormLabel>
                <Input {...register('tenantContactName')} bg="white" />
                <FormErrorMessage>
                  {!!errors.tenantContactName &&
                    errors.tenantContactName.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl isRequired isInvalid={!!errors.tenantContactEmail}>
                <FormLabel>Tenant Contact Email</FormLabel>
                <Input
                  type="email"
                  {...register('tenantContactEmail')}
                  bg="white"
                />
                <FormErrorMessage>
                  {!!errors.tenantContactEmail &&
                    errors.tenantContactEmail.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            {isCreate && (
              <GridItem colSpan={1}>
                <FormControl isRequired isInvalid={!!errors.tenantTypeId}>
                  <FormLabel>Tenant Type</FormLabel>
                  <Select
                    id="tenantTypeId"
                    bg="white"
                    placeholder="Select Tenant Type"
                    // defaultValue={0}
                    {...register('tenantTypeId', {
                      required: 'Tenant Type is required',
                    })}
                    onChange={handleTenantTypeChange}
                  >
                    <option value={2}>Title</option>
                    <option value={4}>City</option>
                  </Select>
                  <FormErrorMessage>
                    {!!errors.tenantTypeId && errors.tenantTypeId.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
            )}
            {(unattachedCities && tenantType === 4) ? (
              <GridItem colSpan={1}>
                <FormControl isRequired isInvalid={!!errors.tenantCity}>
                  <FormLabel>Cities</FormLabel>
                  <ChakraSelect
                    isMulti
                    isDisabled={isDisabledCities}
                    defaultValue={tenantCities ? tenantCities.map((city) => ({
                      value: city.cityId,
                      label: city.cityName,
                    })): []}
                    options={unattachedCities.map((city) => ({
                      value: city.cityId,
                      label: city.cityName,
                    }))}
                    // value={selectedOptions}
                    onChange={handleCityChange}
                    isSearchable
                    isClearable
                  />
                  <FormErrorMessage>
                    {!!errors.tenantCity && errors.tenantCity.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
            ) : null}
          </Grid>
          <Flex justifyContent="flex-end" paddingY={[4, 6]} mt={4}>
            <Button
              colorScheme="green"
              mr={3}
              type="submit"
              isLoading={isSubmitting}
            >
              Submit
            </Button>
            <Button colorScheme="red" onClick={onCancelClick}>
              Cancel
            </Button>
          </Flex>
        </form>
      </Box>
    </Flex>
  );
};

export default TenantInfo;
