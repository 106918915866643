import React, { useEffect, useState } from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import TitleDashboard from './pages/Title/Dashboard';
import TitleCreateTransaction from './pages/Title/CreateTransaction';
import TitleViewTransactionDetail from './pages/Title/ViewTransactionDetail';
import CityDashboard from './pages/City/Dashboard';
import CityViewTransactionDetail from './pages/City/ViewTransactionDetail';
import NotAuthorized from './components/NotAuthorized';
import { TransactionProvider } from './Context/TransactionContext';
import '@fontsource-variable/dm-sans';
import '@fontsource-variable/plus-jakarta-sans';
import theme from './themes/theme';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import Layout from './components/Layout';
import PrivateRoute from './components/PrivateRoute';
import { PortalDashboard } from './pages/Portal/Dashboard';
import { PortalTenants } from './pages/Portal/Tenants';
import { TenantDetails } from './components/Tenant/TenantDetails';
import { useFetchUser } from './hooks/useUser';
import UserContext from './Context/UserContext';
import { useFetchDocumentStatuses } from './hooks/useStaticData';

// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

function App() {
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  const titleRoles = ['TitleAdmin', 'TitleStaff'];
  const cityRoles = ['CityAdmin', 'CityStaff'];
  const portalRoles = ['PortalAdmin'];
  const tenantAdminRoles = ['CityAdmin', 'TitleAdmin'];
  const tenantType = ((auth.user?.profile?.tenant_type ?? '') as String)
    .trim()
    .toLowerCase();
  const userId = auth.user?.profile?.sub;
  const { data: user, isLoading: isUserLoading } = useFetchUser(userId ?? '');
  const { data: fetchStatuses } = useFetchDocumentStatuses();

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin, fetchStatuses]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      console.log('User is authenticated', auth.user);
    } else {
      console.log('User is not authenticated');
    }
  }, [auth.isAuthenticated, auth.user]);

  switch (auth.activeNavigator) {
    case 'signinSilent':
      return <div>Signing you in...</div>;
    case 'signoutRedirect':
      return <div>Signing you out...</div>;
  }

  if (auth.activeNavigator || auth.isLoading || isUserLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    auth.signinRedirect();
    // return <div>Oops... {auth.error.message}</div>;
  }

  return user ? (
    <ChakraProvider theme={theme}>
      <TransactionProvider>
        <UserContext.Provider value={user}>
          <Router>
            <Layout>
              <Routes>
                <Route
                  path="/"
                  element={
                    tenantType === 'title' ||
                    tenantType === 'city' ||
                    tenantType === 'portal' ? (
                      <Navigate to={`/${tenantType}/dashboard`} replace />
                    ) : (
                      <Navigate to="/not-authorized" replace />
                    )
                  }
                />
                <Route
                  path="/title/dashboard"
                  element={
                    <PrivateRoute
                      element={<TitleDashboard />}
                      allowedRoles={titleRoles}
                    />
                  }
                />
                <Route
                  path="/title/createtransaction"
                  element={
                    <PrivateRoute
                      element={<TitleCreateTransaction />}
                      allowedRoles={titleRoles}
                    />
                  }
                />
                <Route
                  path="/title/viewtransaction/:id"
                  element={
                    <PrivateRoute
                      element={<TitleViewTransactionDetail />}
                      allowedRoles={titleRoles}
                    />
                  }
                />
                <Route
                  path="/city/dashboard"
                  element={
                    <PrivateRoute
                      element={<CityDashboard />}
                      allowedRoles={cityRoles}
                    />
                  }
                />
                <Route
                  path="/city/viewtransaction/:id"
                  element={
                    <PrivateRoute
                      element={<CityViewTransactionDetail />}
                      allowedRoles={cityRoles}
                    />
                  }
                />
                <Route
                  path="/portal/dashboard"
                  element={
                    <PrivateRoute
                      element={<PortalDashboard />}
                      allowedRoles={portalRoles}
                    />
                  }
                />
                <Route
                  path="/portal/tenants"
                  element={
                    <PrivateRoute
                      element={<PortalTenants />}
                      allowedRoles={portalRoles}
                    />
                  }
                />
                <Route
                  path="/portal/tenants/:tenantIdParam"
                  element={
                    <PrivateRoute
                      element={<TenantDetails />}
                      allowedRoles={portalRoles}
                    />
                  }
                />
                <Route
                  path="/admin/tenant-details"
                  element={
                    <PrivateRoute
                      element={<TenantDetails />}
                      allowedRoles={tenantAdminRoles}
                    />
                  }
                />
                <Route path="/not-authorized" element={<NotAuthorized />} />
                <Route path="/*" element={<NotAuthorized />} />
              </Routes>
            </Layout>
          </Router>
        </UserContext.Provider>
      </TransactionProvider>
      {/* <ReactQueryDevtools/> */}
    </ChakraProvider>
  ) : null;
}

export default App;
