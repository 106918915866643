import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from 'react-oidc-context';
import { oidcConfig } from './services/config/oidcConfig';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './services/config/reactQueryClient';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
// console.log(oidcConfig, "oidcConfig");
root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </AuthProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
