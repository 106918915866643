import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DeleteDocumentTypeById, fetchDocumentsById, fetchDocumentsHistory, UpdateDocumentByType, UpdateDocumentStatus, UploadDocumentByType } from "../services/api/fileService";

export const useFetchDocuments = (id: number) => {
    return useQuery({
        queryKey: ["getDocumentsById", { id }],
        queryFn: () => fetchDocumentsById(id),
        staleTime: 60000,
    });
};
export const useFetchDocumentsHistory = (id: number) => {
    return useQuery({
        queryKey: ["fetchDocumentsHistory", { id }],
        queryFn: () => fetchDocumentsHistory(id),
    });
};

/* export const useDownloadDocument = (documentName: string, transactionId: number, documentTypeId: number) => {
    return useQuery({
        queryKey: ["getDocumentsById", { documentName, transactionId, documentTypeId }],
        queryFn: () => DownloadDocument(documentName, transactionId, documentTypeId),
        enabled: false,
    });
}; */

export const useUploadDocumentType = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ file, transactionId, documentTypeId }: { file: File, transactionId: number, documentTypeId: number }) => UploadDocumentByType(file, transactionId, documentTypeId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["getDocumentsById"] });
        },
    });
};

export const useUpdateDocumentType = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ file, transactionId, documentTypeId, fileToUpdate }: { file: File, transactionId: number, documentTypeId: number, fileToUpdate: string }) => UpdateDocumentByType(file, transactionId, documentTypeId, fileToUpdate),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["getDocumentsById"] });
        },
    });
};

export const useDeleteDocumentType = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ transactionId, documentTypeId }: { transactionId: number, documentTypeId: number }) => DeleteDocumentTypeById(transactionId, documentTypeId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["getDocumentsById"] });
        },
    });
};

export const useUpdateDocumentStatus = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ transactionId, documentTypeId, toStatusId }: { transactionId: number, documentTypeId: number, toStatusId: number }) => UpdateDocumentStatus(transactionId, documentTypeId, toStatusId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["getDocumentsById"] });
        },
    });
};