import { AxiosRequestConfig } from 'axios';
import { CreateTransaction, Transaction } from '../../types/Transaction';
import { axiosClient } from '../config/axiosClient';

export const fetchTransactions = async () => {
  const url = '/Transaction/GetTransactions';
  const response = await axiosClient.get(url);
  return response.data as Transaction[];
};

export const fetchTransactionById = async (id: number) => {
  const url = '/Transaction/GetTransactions';
  const config: AxiosRequestConfig = {
    params: {
      transactionId: id,
    },
  };
  const response = await axiosClient.get(url, config);
  return response.data as Transaction[];
};

export const createTransaction = async (transaction: CreateTransaction) => {
  const url = '/Transaction/CreateTransaction';
  const response = await axiosClient.post(url, { transaction });
  return response;
};

export const updateTransactionStatus = async (
  transactionId: number,
  toStatus: string,
) => {
  const url = '/Transaction/UpdateTransactionStatus';
  const config: AxiosRequestConfig = {
    params: {
      transactionId,
      toStatus,
    },
  };
  const response = await axiosClient.put(url, null, config);
  return response;
};
export const getTitleTransactions = async (id: number) => {
  const url = '/Transaction/GetTitleTransactions';
  const config: AxiosRequestConfig = {
    params: {
      titleTenantId: id,
    },
  };
  const response = await axiosClient.get(url, config);
  return response.data as Transaction[];
};

export const getCityTransactions = async (id: number | null) => {
  const url = '/Transaction/GetCityTransactions';
  const config: AxiosRequestConfig = {
    params: {
      cityTenantId: id,
    },
  };
  const response = await axiosClient.get(url, config);
  return response.data as Transaction[];
};
