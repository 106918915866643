import { DownloadDocument } from "../services/api/fileService";

export const handleFileDownload = async (
    documentName: string,
    transactionId: number,
    documentTypeId: number,
  ) => {
    try {
      const response = await DownloadDocument(documentName, transactionId, documentTypeId);
  
      if (response.status !== 200) {
        throw new Error('Failed to download file');
      }
  
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', documentName);
  
      document.body.appendChild(link);
      link.click();
  
      document.body.removeChild(link);
  
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };
  