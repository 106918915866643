import React from 'react';
import { Flex, Box, Text, Image } from '@chakra-ui/react';
import Unopened from '../assets/Unopened.svg';
import correct from '../assets/correct.svg';
import Rejected from '../assets/Rejected.svg';
import Inprogress from '../assets/Inprogress.svg';

export const DocumentIndicators = () => {
  const indicators = [
    { icon: correct, text: "Accepted" },
    { icon: Unopened, text: "Unopened" },
    { icon: Inprogress, text: "In Progress" },
    { icon: Rejected, text: "Rejected" }
  ];

  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      alignItems={{ base: "flex-start", md: "center" }}
      paddingTop={0}
    >
      <Box
        marginBottom={{ base: "15px", md: 0 }}
        marginRight={{ base: "0", md: "20px" }}
      >
        <Text
          fontSize={{ base: "14px", md: "lg" }}
          fontWeight="700"
          fontFamily="DM Sans Variable"
          lineHeight={{ base: "18.23px", md: "normal" }}
          letterSpacing="-0.02em"
          marginBottom={{ base: "5px", md: 0 }}
          marginRight={{ base: "0", md: "20px" }}
        >
          Document Indicators
        </Text>
        <Flex alignItems="center" marginTop={{ base: "3px", md: "5px" }}>
          {/* Iterate over the array and render each indicator */}
          {indicators.map((indicator, index) => (
            <Box
              key={index}
              marginRight={{ base: "8px", md: "10px" }}
              display="flex"
              alignItems="center"
              gap={"20px"}
            >
              <Image
                src={indicator.icon}
                alt={`${indicator.text} Icon`}
                boxSize={"24px"}
                // width={"24px"}
                // height={"24px"}
              />
              <Text
                fontSize={{ base: "14px", md: "lg" }}
                fontWeight="500"
                fontFamily="DM Sans Variable"
                lineHeight={{ base: "18.23px", md: "20.16px" }}
                letterSpacing="-0.02em"
                textAlign="left"
                color="#848181"
                // marginLeft="8px"
                pr={"10px"}
              >
                {indicator.text}
              </Text>
            </Box>
          ))}
        </Flex>
      </Box>
    </Flex>
  );
};
