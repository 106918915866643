export const New = 'New';
export const Completed = 'Completed';
export const InProgress = 'In Progress';
export const All = 'All';
export const NotCompleted = 'Not completed (\'New\' or \'In Progress\')';

export const FilterMap: {key: string, value: string}[] = [
    {key: 'All', value: 'All'},
    {key: 'New', value: 'New'},
    {key: 'InProgress', value: 'In Progress'},
    {key: 'NotCompleted', value: 'Not completed (\'New\' or \'In Progress\')'},
    {key: 'Completed', value: 'Completed'}
];