// components/PrivateRoute.tsx
import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
interface PrivateRouteProps {
  element: React.ReactElement;
  allowedRoles: string[];
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  element,
  allowedRoles,
}) => {
  const location = useLocation();
  const auth = useAuth();
  const user_profile = auth.user?.profile;

  const client_id = user_profile?.aud as string;
  const user_roles = user_profile?.[`${client_id}_roles`] as string[];

  return allowedRoles.find((role) => user_roles.includes(role)) ? (
    element
  ) : !auth.isAuthenticated ? (
    <Navigate to="/" state={{ from: location }} replace />
  ) : (
    <Navigate to="/not-authorized" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
