import React from 'react';
import { Input, InputGroup, InputLeftElement, Icon } from '@chakra-ui/react';
import { MdSearch } from 'react-icons/md';

interface SearchInputProps {
  searchTerm: string;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  inputWidth?: string;
}

const SearchInput: React.FC<SearchInputProps> = ({
  searchTerm,
  onSearchChange,
  placeholder = 'Search...',
  inputWidth = '588px',
}) => {
  return (
    <InputGroup width="auto">
      <InputLeftElement pointerEvents="none">
        <Icon as={MdSearch} />
      </InputLeftElement>
      <Input
        type="text"
        placeholder={placeholder}
        value={searchTerm}
        onChange={onSearchChange}
        style={{
          width: inputWidth,
          height: '40px',
          padding: '10px 10px 10px 40px',
          gap: '10px',
          borderRadius: '20px',
          border: '1px solid rgb(163, 174, 208)',
          background: 'rgb(255, 255, 255)',
        }}
      />
    </InputGroup>
  );
};

export default SearchInput;

export const filterArrayByTerm = <T,>(
  array: T[],
  searchTerm: string,
  keys: (keyof T)[],
): T[] => {
  if (!searchTerm) return array;

  return array.filter((item) =>
    keys.some((key) =>
      String(item[key]).toLowerCase().includes(searchTerm.toLowerCase()),
    ),
  );
};
