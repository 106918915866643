import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { createTenant, getTenantById, fetchTenants, updateTenant } from "../services/api/tenantService";
import { CreateTenant } from "../types/Tenant";

export const useFetchTenants = () => {
  return useQuery({
    queryKey: ['getTenants'],
    queryFn: fetchTenants,
  });
};

export const useFetchTenantById = (id: number) => {
  return useQuery({
    queryKey: ['getTenantById', { id }],
    queryFn: () => getTenantById(id),
  });
};

export const useCreateTenant = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (tenantData: CreateTenant) => createTenant(tenantData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getTenants'] });
    },
  });
};

export const useUpdateTenant = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ tenantId, tenantData }: { tenantId: number; tenantData: CreateTenant }) => updateTenant(tenantId, tenantData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getTenants"] });
      queryClient.invalidateQueries({ queryKey: ["getTenantById"] });
    },
  });
};