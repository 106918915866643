import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Flex,
} from '@chakra-ui/react';
import { CreateUser } from '../../types/User';

interface StaffModalProps {
  isOpen: boolean;
  onClose: () => void;
  onStaffSubmit: (values: CreateUser) => void;
  onResetPassword?: () => void;
  userData: CreateUser;
  isUpdate?: boolean;
}

const StaffModal: React.FC<StaffModalProps> = ({
  isOpen,
  onClose,
  userData,
  onStaffSubmit,
  onResetPassword,
  isUpdate = false,
}) => {

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm<CreateUser>({
    defaultValues: {
      email: userData.email,
      firstName: userData.firstName,
      lastName: userData.lastName,
      phoneNumber: userData.phoneNumber,
      tenantId: userData.tenantId,
      role: userData.role
    },
  });

  useEffect(() => {
    reset(userData);
  }, [reset, userData]);

  const onModalClose = () => {
    // reset();
    onClose();
  };
  const onClickSave = (values: CreateUser) => {
    onStaffSubmit(values);
    reset();
  };

  return (
    <Modal isOpen={isOpen} onClose={onModalClose}>
      <ModalOverlay />
      <ModalContent>
        <form>
          <ModalHeader>Add Staff</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired isInvalid={!!errors.email}>
              <FormLabel fontFamily={'DM Sans Variable'}>Email</FormLabel>
              <Input
                placeholder="Email"
                id="email"
                isDisabled={isUpdate}
                fontFamily={'DM Sans Variable'}
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: 'Invalid email address',
                  },
                })}
              />
              <FormErrorMessage>
                {!!errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.firstName}>
              <FormLabel fontFamily={'DM Sans Variable'}>First Name</FormLabel>
              <Input
                placeholder="First Name"
                fontFamily={'DM Sans Variable'}
                id="firstName"
                {...register('firstName', {
                  required: 'First Name is required',
                })}
              />
              <FormErrorMessage>
                {!!errors.firstName && errors.firstName.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.lastName}>
              <FormLabel fontFamily={'DM Sans Variable'}>Last Name</FormLabel>
              <Input
                placeholder="Last Name"
                id="lastName"
                fontFamily={'DM Sans Variable'}
                {...register('lastName', {
                  required: 'Last Name is required',
                })}
              />
              <FormErrorMessage>
                {!!errors.lastName && errors.lastName.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={!!errors.phoneNumber}>
              <FormLabel fontFamily={'DM Sans Variable'}>
                Phone Number
              </FormLabel>
              <Input
                placeholder="Phone Number"
                id="phoneNumber"
                type="tel"
                fontFamily={'DM Sans Variable'}
                {...register('phoneNumber', {
                  required: 'Phone Number is required',
                  pattern: {
                    value: /^[0-9]{10}$/,
                    message: 'Invalid phone number',
                  },
                  minLength: {
                    value: 10,
                    message: 'length should be 10',
                  },
                  maxLength: {
                    value: 10,
                    message: 'length should be 10',
                  },
                })}
              />
              <FormErrorMessage>
                {!!errors.phoneNumber && errors.phoneNumber.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.role?.isAdminUser}>
                <FormLabel>Role</FormLabel>
                <Select
                  id="tenantTypeId"
                  bg="white"
                  placeholder="Select Tenant Type"
                  // defaultValue={0}
                  {...register('role.isAdminUser', {
                    required: 'Tenant Type is required',
                  })}
                >
                  <option value={1}>Admin</option>
                  <option value={0}>Staff</option>
                </Select>
                <FormErrorMessage>
                  {!!errors.role?.isAdminUser && errors.role?.isAdminUser.message}
                </FormErrorMessage>
              </FormControl>
            {/* <Controller
              name="role..0" //list of strings // using only one group currently
              control={control}
              rules={{ required: 'Role is required' }}
              render={({
                field: { value, name, onChange, onBlur, ref },
                fieldState: { error },
              }) => (
                <FormControl id="groups" isRequired isInvalid={!!error}>
                  <FormLabel>Role</FormLabel>
                  <Select
                    name={name}
                    placeholder="Select Role"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={ref}
                    // defaultValue={userData.groups[0]}
                  >
                    <option
                      selected={userData.role.isAdminUser === 0}
                      value={userData.role.tenantType + '/Staff'}
                    >
                      Staff
                    </option>
                    <option
                      selected={userData.role.isAdminUser === 1}
                      value={userData.attributes.tenant_type + '/Admin'}
                    >
                      Admin
                    </option>
                  </Select>
                  <FormErrorMessage>
                    {!!error && error.message}
                  </FormErrorMessage>
                </FormControl>
              )}
            /> */}
          </ModalBody>
          <ModalFooter>
            {isUpdate && (
              <Flex justifyContent="flex-start" width="100%">
              <Button colorScheme="blue" mr={2} onClick={onResetPassword}>
                Reset Password
              </Button>
            </Flex>
            )}
            <Flex justifyContent="flex-end" width="100%">
              <Button
                colorScheme="green"
                mr={3}
                isLoading={isSubmitting}
                onClick={handleSubmit(onClickSave)}
              >
                Save
              </Button>
              <Button
                // variant="ghost"
                colorScheme="red"
                onClick={() => onModalClose()}
                isLoading={isSubmitting}
              >
                Cancel
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default StaffModal;
