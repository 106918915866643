import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Spinner,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import { useFetchTenantById, useUpdateTenant } from '../../hooks/useTenant';
import TenantInfo from './TenantInfo';
import TenantStaff from '../Staff/TenantStaff';
import {
  useFetchUsersByTenantId,
  useUpdateUserAccess,
} from '../../hooks/useUser';
import { CreateTenant } from '../../types/Tenant';
import {
  useFetchCitiesByTenantId,
  useUpdateTenantIdForCities,
} from '../../hooks/useStaticData';
import { useAuth } from 'react-oidc-context';
import { useToast } from '@chakra-ui/react';

export const TenantDetails: React.FC = () => {
  const navigate = useNavigate();
  const { tenantId: tenantIdParam } = useParams<{ tenantId: string }>();
  const { state } = useLocation();
  const { tenantId: tenantIdState } = state;
  const tenantId = Number(tenantIdParam ?? tenantIdState);
  const { data: tenant, isLoading, error } = useFetchTenantById(tenantId);
  const { data: getStaff } = useFetchUsersByTenantId(tenantId);
  const { data: citiesByTenantId } = useFetchCitiesByTenantId(tenantId);
  const { mutateAsync: updateTenant } = useUpdateTenant();
  const { mutateAsync: updateTenantIdForCities } = useUpdateTenantIdForCities();

  const auth = useAuth();
  const user_profile = auth.user?.profile;
  const userTenantType = ((user_profile?.tenant_type ?? '') as String)
    .trim()
    .toLowerCase();
  const { mutateAsync: updateUserAccess } = useUpdateUserAccess();
  const toast = useToast();
  const handleUpdateTenant = async (
    tenantData: CreateTenant,
    cityIds: number[],
  ) => {
    await updateTenant(
      {
        tenantId: tenant?.tenantId ?? 0,
        tenantData,
      },
      {
        onSuccess: async (variables) => {
          await updateTenantIdForCities({
            cityIds: cityIds,
            tenantId: variables.tenantId,
          });
        },
      },
    );

    // navigating to previous page
    navigate(-1);
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Text color="red.500">Failed to load tenant data {tenantId}</Text>
      </Box>
    );
  }

  if (!tenant) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Text>No tenant data available</Text>
      </Box>
    );
  }
  const handleAccessToggle = async (userId: string, isActive: number) => {
    try {
      const toggleAccess = isActive === 1 ? 'disable' : 'enable';
      await updateUserAccess({ userId, access: toggleAccess });
      toast({
        title: `User access has been changed to ${toggleAccess}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error toggling user access:', error);
      toast({
        title: 'Failed to change user access.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    // <Flex>
    <Box padding={[4, 8]}>
      {/* <Flex
        justifyContent="space-between"
        alignItems="center"
        paddingY={[4, 8]}
        paddingX={[4, 8]}
      >
        <Heading fontFamily="DM Sans Variable" fontSize="34px" fontWeight="700">
          Tenant Details : {tenant.tenantId}
        </Heading>
      </Flex> */}
      <Tabs variant="enclosed">
        <TabList
          mb="1em"
          sx={{
            fontFamily: 'DM Sans Variable',
            fontSize: '34px',
            fontWeight: '700',
          }}
          paddingX={[4, 8]}
        >
          <Tab
            fontFamily={'DM Sans Variable'}
            fontSize={'17px'}
            fontWeight={'700'}
          >
            Tenant Info
          </Tab>
          <Tab
            fontFamily={'DM Sans Variable'}
            fontSize={'17px'}
            fontWeight={'700'}
          >
            Staff
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <TenantInfo
              tenantData={tenant}
              tenantCities={citiesByTenantId ?? []}
              onClickSave={({ values, selectedCityIds }) =>
                handleUpdateTenant(values, selectedCityIds)
              }
              onCancel={() => {navigate(-1)}}
              isDisabledCities={!(userTenantType === 'portal')}
            />
          </TabPanel>
          <TabPanel>
            <TenantStaff
              staffData={getStaff ?? []}
              tenantId={tenant.tenantId}
              tenant_type={
                tenant.tenantTypeId === 2
                  ? 'Title'
                  : tenant.tenantTypeId === 4
                  ? 'City'
                  : ''
              }
              handleAccessToggle={handleAccessToggle}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
    // </Flex>
  );
};
