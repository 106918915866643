import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
} from '@chakra-ui/react';
import { UpdateUser } from '../types/User';

interface ProfileModalProps {
  isOpen: boolean;
  onClose: () => void;
  profileData: UpdateUser;
  onProfileSubmit: (values: UpdateUser) => void;
  onPasswordReset: () => void;
}

const ProfileModal: React.FC<ProfileModalProps> = ({
  isOpen,
  onClose,
  profileData,
  onProfileSubmit,
  onPasswordReset,
}) => {
  const [showEditProfile, setShowEditProfile] = useState(true);
  //const [showChangePassword, setShowChangePassword] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<UpdateUser>({
    defaultValues: {
      firstName: profileData.firstName,
      lastName: profileData.lastName,
      phoneNumber: profileData.phoneNumber,
      email: profileData.email,
    },
  });

  React.useEffect(() => {
    // console.log('mounted profile modal');
    reset({
      firstName: profileData.firstName,
      lastName: profileData.lastName,
      phoneNumber: profileData.phoneNumber,
      email: profileData.email,
    });
  }, [
    reset,
    profileData.firstName,
    profileData.lastName,
    profileData.phoneNumber,
    profileData.email,
  ]);

  const openEditProfile = () => {
    setShowEditProfile(true);
    //setShowChangePassword(false);
  };

  //const openChangePassword = () => {
  //  setShowEditProfile(false);
  //  setShowChangePassword(true);
  //};

  const onModalClose = () => {
    onClose();
    reset({
      firstName: profileData.firstName,
      lastName: profileData.lastName,
      phoneNumber: profileData.phoneNumber,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onModalClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontFamily={'DM Sans Variable'}>
          Profile Settings
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justifyContent="space-between">
            <Flex justifyContent="space-between" w="full" alignItems="center">
              <Text
                textAlign="center"
                onClick={openEditProfile}
                cursor="pointer"
                fontFamily={'DM Sans Variable'}
                bg={showEditProfile ? 'green.100' : 'transparent'}
                p={showEditProfile ? '4px' : '0'}
                width="100%"
              >
                Edit Profile
              </Text>
              {/* <Divider orientation="vertical" mx="2" height="20px" />
              <Text
                textAlign="center"
                onClick={openChangePassword}
                cursor="pointer"
                fontFamily={'DM Sans Variable'}
                bg={showChangePassword ? 'green.100' : 'transparent'}
                p={showChangePassword ? '4px' : '0'}
                width="100%"
              >
                Change Password
              </Text> */}
            </Flex>
          </Flex>

          {showEditProfile && (
            <Box mt="4">
              <form onSubmit={handleSubmit(onProfileSubmit)}>
                <Flex flexDirection="column" gap="4">
                  <FormControl>
                    <FormLabel fontFamily={'DM Sans Variable'}>Email</FormLabel>
                    <Input
                      placeholder="Email"
                      name="email"
                      type="email"
                      fontFamily={'DM Sans Variable'}
                      value={profileData.email}
                      disabled // Disable the input field - email
                    />
                  </FormControl>
                  <FormControl isInvalid={!!errors.firstName}>
                    <FormLabel fontFamily={'DM Sans Variable'}>
                      First Name
                    </FormLabel>
                    <Input
                      placeholder="First Name"
                      fontFamily={'DM Sans Variable'}
                      id="firstName"
                      {...register('firstName', {
                        required: 'First Name is required',
                      })}
                    />
                    <FormErrorMessage>
                      {!!errors.firstName && errors.firstName.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={!!errors.lastName}>
                    <FormLabel fontFamily={'DM Sans Variable'}>
                      Last Name
                    </FormLabel>
                    <Input
                      placeholder="Last Name"
                      id="lastName"
                      fontFamily={'DM Sans Variable'}
                      {...register('lastName', {
                        required: 'Last Name is required',
                      })}
                    />
                    <FormErrorMessage>
                      {!!errors.lastName && errors.lastName.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={!!errors.phoneNumber}>
                    <FormLabel fontFamily={'DM Sans Variable'}>
                      Phone Number
                    </FormLabel>
                    <Input
                      placeholder="Phone Number"
                      id="phoneNumber"
                      type="number"
                      // pattern="[0-9]*"
                      fontFamily={'DM Sans Variable'}
                      {...register('phoneNumber', {
                        required: 'Phone Number is required',
                        // pattern: {
                        //   value: /[0-9]*/,
                        //   message: "phoneNumber is not valid."
                        // },
                        minLength: {
                          value: 10,
                          message: 'length should be 10',
                        },
                        maxLength: {
                          value: 10,
                          message: 'length should be 10',
                        },
                      })}
                    />
                    <FormErrorMessage>
                      {!!errors.phoneNumber && errors.phoneNumber.message}
                    </FormErrorMessage>
                  </FormControl>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Button
                      colorScheme="blue"
                      cursor={'pointer'}
                      fontFamily={'DM Sans Variable'}
                      onClick={onPasswordReset} // Call the handlePasswordReset function
                    >
                      Reset Password
                    </Button>

                    <Flex alignItems="center">
                      <Button
                        type="submit"
                        colorScheme="green"
                        fontFamily={'DM Sans Variable'}
                        cursor={'pointer'}
                        isLoading={isSubmitting}
                        mr="2"
                      >
                        Save
                      </Button>
                      <Button
                        onClick={() => onModalClose()}
                        colorScheme="red"
                        fontFamily={'DM Sans Variable'}
                        cursor={'pointer'}
                      >
                        Cancel
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </form>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ProfileModal;
