import { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Badge,
} from '@chakra-ui/react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { SmallCloseIcon } from '@chakra-ui/icons';
import { MdNotificationsNone } from 'react-icons/md';
import { useAuth } from 'react-oidc-context';
import ProfileModal from './ProfileModal';
import { useResetPassword } from '../hooks/usePasswordReset';
import { useUpdateUser } from '../hooks/useUser';
import { UpdateUser } from '../types/User';
import TenantAdminModal from './TenantAdminModal';
import { useUserContext } from '../Context/UserContext';
// import { FormattedNotifications } from '../utils/Notifications';
import { TenantType } from '../types/Tenant';
import {
  useFetchCityNotifications,
  useFetchTitleNotifications,
} from '../hooks/useNotification';
import { Notification } from '../types/Notification';
import { formatDate } from '../utils/DateUtils';

interface FormattedNotification {
  id: number;
  title: string;
  message1: string;
  message2: string;
  createdAt: string;
  viewed: boolean;
}

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const userInfo = useUserContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isCityStaffModalOpen, setIsCityStaffModalOpen] = useState(false);
  const { mutate: updateUser } = useUpdateUser();
  const { mutate: resetPassword } = useResetPassword();
  const user_profile = auth.user?.profile;
  const isCreateTransactionRoute =
    location.pathname === '/title/createtransaction';
  const tenantType = ((user_profile?.tenant_type ?? '') as String)
    .trim()
    .toLowerCase() as TenantType;
  const isTenantAdmin = userInfo.isAdminUser === 1 ? true : false;

  const FormattedNotifications = (
    tenantId: number,
    tenantType: 'city' | 'title' | 'portal',
  ) => {
    const fetchCityNotifications = useFetchCityNotifications(tenantId);
    const fetchTitleNotifications = useFetchTitleNotifications(tenantId);
    const notifications =
      tenantType === 'title'
        ? fetchTitleNotifications.data
        : tenantType === 'city'
        ? fetchCityNotifications.data
        : null;
    const sortedNotifications = notifications?.sort((a, b) => {
      const dateA = new Date(a.triggeredAt);
      const dateB = new Date(b.triggeredAt);
      return dateB.getTime() - dateA.getTime();
    });
    const notificationsObject: FormattedNotification[] =
      sortedNotifications?.map((notification: Notification) => ({
        id: notification.notificationId,
        title: 'Transaction No: ' + String(notification.transactionId),
        message1:
          'Document Type "' + notification.transactionDocumentTypeName + '"',
        message2: 'status updated to "' + notification.toStatusName + '"',
        createdAt: formatDate(notification.triggeredAt),
        viewed: notification.notificationProcessed === 0 ? false : true,
      })) ?? [];
    return notificationsObject;
  };

  const notifications = FormattedNotifications(userInfo.tenantId, tenantType);

  const onCloseCityStaffModal = () => {
    setIsCityStaffModalOpen(false);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onOpen = () => {
    setIsOpen(true);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleProfileSubmit = (values: UpdateUser) => {
    const requestBody = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
    };

    updateUser(
      { userId: userInfo.userId, userData: requestBody },
      {
        onSuccess: () => {
          console.log('Profile updated successfully');
          onClose();
        },
        onError: (error: any) => {
          console.error('Error updating profile:', error.message);
        },
      },
    );
  };

  const handlePasswordReset = () => {
    resetPassword(userInfo.userId, {
      onSuccess: () => {
        console.log('Password reset successfully');
        onClose();
      },
      onError: (error) => {
        console.error('Error resetting password:', error.message);
      },
    });
  };

  const handleNotificationClick = () => {};

  return (
    <Box
      className="css-0"
      width="100%"
      height="110px"
      padding="20px 40px"
      justifyContent="space-between"
      boxShadow="rgba(0, 0, 0, 0.1) 0px 5px 10px 0px"
      backgroundColor="white"
      display={{ base: 'flex', md: 'flex' }}
      alignItems="center"
    >
      <Link to="/">
        <Image
          src="/image/eHomestead.png"
          alt="Logo"
          width="auto"
          height="100%"
        />
      </Link>
      <Flex
        w={{ sm: '100%', md: 'auto' }}
        alignItems="center"
        flexDirection="row"
        gap={5}
      >
        {!isCreateTransactionRoute && (
          <Box alignItems="center" display="flex">
            <Menu onOpen={handleNotificationClick}>
              <MenuButton p="0px">
                <Flex position="relative">
                  <Icon
                    mt="6px"
                    as={MdNotificationsNone}
                    w="40px"
                    h="40px"
                    color="black"
                  />
                  {notifications.length > 0 && (
                    <Badge
                      colorScheme="red"
                      borderRadius="full"
                      position="absolute"
                      top="-1px"
                      right="-1px"
                      transform="translate(50%, -50%)"
                    >
                      {notifications.length}
                    </Badge>
                  )}
                </Flex>
              </MenuButton>
              <MenuList maxH={'550px'} overflow={'auto'}>
                {notifications.length > 0 ? (
                  notifications.map((notification, index) => (
                    <MenuItem
                      key={index}
                      ps="20px"
                      // pt="16px"
                      // pb="10px"
                      w="100%"
                      // borderBottom="1px solid"
                      borderTop={index === 0 ? 'none' : '1px solid'}
                      borderColor="#E6ECFA"
                      fontSize="sm"
                      fontWeight="700"
                      color="black"
                    >
                      <Box>
                        <Text fontSize={'md'}>{notification.title}</Text>
                        <Text color={'grey'} fontSize={'sm'}>
                          {notification.message1}
                        </Text>
                        <Text color={'grey'} fontSize={'sm'}>
                          {notification.message2} on {notification.createdAt}
                        </Text>
                      </Box>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem color="black">No Notifications</MenuItem>
                )}
              </MenuList>
            </Menu>
            <Center height="50px">
              <Divider orientation="vertical" ml="18px" mr="20px" />
            </Center>
            <Menu>
              <MenuButton p="0px">
                <Avatar size="xs" w="40px" h="40px" />
              </MenuButton>
              <MenuList borderRadius="20px">
                <Flex w="100%" mb="0px">
                  <Text
                    ps="20px"
                    pt="16px"
                    pb="10px"
                    w="100%"
                    borderBottom="1px solid"
                    borderColor="#E6ECFA"
                    fontSize="sm"
                    fontWeight="700"
                    color="black"
                    fontFamily={'DM Sans'}
                  >
                    👋&nbsp; Hey,&nbsp;&nbsp;{user_profile?.name}
                  </Text>
                </Flex>
                <MenuItem
                  _hover={{ bg: 'none' }}
                  _focus={{ bg: 'none' }}
                  color="black"
                  borderRadius="8px"
                  textColor={'blue'}
                  onClick={onOpen}
                  px="14px"
                >
                  Profile Settings
                </MenuItem>
                {(tenantType === 'portal' || isTenantAdmin) && (
                  <MenuItem
                    _hover={{ bg: 'none' }}
                    _focus={{ bg: 'none' }}
                    color="black"
                    borderRadius="8px"
                    px="14px"
                    textColor={'blue'}
                    onClick={
                      tenantType === 'portal'
                        ? () => navigate('/portal/tenants')
                        : () =>
                            navigate('/admin/tenant-details', {
                              state: { tenantId: userInfo.tenantId },
                            })
                      // : onCityStaffMenuClick
                    }
                  >
                    Tenant Settings
                  </MenuItem>
                )}
                <MenuItem
                  _hover={{ bg: 'none' }}
                  _focus={{ bg: 'none' }}
                  color="black"
                  borderRadius="8px"
                  px="14px"
                  textColor={'red'}
                  onClick={() => auth.signoutRedirect()}
                >
                  Log Out
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        )}
        {isCreateTransactionRoute && (
          <Button
            leftIcon={<SmallCloseIcon />}
            style={{
              border: '1px solid #3DA42F',
              borderRadius: '50px',
              color: '#3DA42F',
              fontSize: '16px',
            }}
            type="button"
            variant="outline"
            colorScheme="green"
            borderRadius="50px"
            fontSize="16px"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        )}
      </Flex>
      <ProfileModal
        isOpen={isOpen}
        onClose={onClose}
        profileData={userInfo}
        onProfileSubmit={(values) => handleProfileSubmit(values)}
        onPasswordReset={handlePasswordReset}
      />
      <TenantAdminModal
        isOpen={isCityStaffModalOpen}
        onClose={onCloseCityStaffModal}
        tenantId={userInfo.tenantId}
      />
    </Box>
  );
};

export default Header;
