import { AxiosRequestConfig } from "axios";
import { TransactionDocument } from "../../types/TransactionDocument";
import { axiosClient } from "../config/axiosClient";
import { DocumentHistory } from "../../types/DocumentHistory";

export const fetchDocumentsById = async (id: number) => {
    const url = '/Files/ListDocumentsByTransactionId';
    const config : AxiosRequestConfig = {
        params: {
            transactionId: id
        },
    };
    const response = await axiosClient.get(url, config);
    return response.data as TransactionDocument[];
}

export const fetchDocumentsHistory = async (id: number) => {
    const url = '/Files/GetDocumentsHistory';
    const config : AxiosRequestConfig = {
        params: {
            transactionId: id
        },
    };
    const response = await axiosClient.get(url, config);
    return response.data as DocumentHistory[];
}

export const DownloadDocument = async (documentName: string, transactionId: number, documentTypeId: number) => {
    const url = '/Files/DownloadDocument';
    const config : AxiosRequestConfig = {
        responseType: 'blob',
        params: {
            documentName,
            transactionId,
            documentTypeId
        },
    };
    const response = await axiosClient.get(url, config);
    return response;
}

export const UploadDocumentByType = async (file: File, transactionId: number, documentTypeId: number) => {
    const form = new FormData();
    form.append('files', file);
    form.append('transactionId', transactionId.toString());
    form.append('documentTypeId', documentTypeId.toString());
    const url = '/Files/UploadDocumentsByFileType';
    const response = await axiosClient.post(url, form);
    return response;
}

export const UpdateDocumentByType = async (file: File, transactionId: number, documentTypeId: number, fileToUpdate: string) => {
    const form = new FormData();
    form.append('files', file);
    form.append('transactionId', transactionId.toString());
    form.append('documentTypeId', documentTypeId.toString());
    form.append('fileToUpdate', fileToUpdate);
    const url = '/Files/UpdateDocumentByFileType';
    const response = await axiosClient.put(url, form);
    return response;
}

export const DeleteDocumentTypeById = async (transactionId: number, documentTypeId: number) => {
    const url = '/Files/DeleteDocumentTypeByTransactionId';
    const config : AxiosRequestConfig = {
        params: {
            transactionId: transactionId,
            documentTypeId: documentTypeId
        },
    };
    const response = await axiosClient.delete(url, config);
    return response;
}

export const UpdateDocumentStatus = async (transactionId: number, documentTypeId: number, toStatusId: number) => {
    const url = '/Files/UpdateDocumentStatus';
    const config : AxiosRequestConfig = {
        params: {
            transactionId,
            documentTypeId,
            toStatusId
        },
    };
    console.log("config ",config)
    const response = await axiosClient.put(url, null, config);
    return response;
}