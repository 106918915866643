import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Transaction } from '../types/Transaction';

const TransactionContext = createContext<{
  transaction: Transaction | null;
  setTransaction: React.Dispatch<React.SetStateAction<Transaction | null>>;
}>({
  transaction: null,
  setTransaction: () => null,
});


export const TransactionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [transaction, setTransaction] = useState<Transaction | null>(null);

  return (
    <TransactionContext.Provider value={{ transaction, setTransaction }}>
      {children}
    </TransactionContext.Provider>
  );
};


export const useTransaction = () => {
  const context = useContext(TransactionContext);
  if (!context) {
    throw new Error('useTransaction must be used within a TransactionProvider');
  }
  return context;
};

export default TransactionContext;
