import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Select,
  ModalFooter,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useUploadDocumentType } from '../../../hooks/useFiles';
import { Dropzone } from '../../../components/Dropzone';

interface DocumentType {
  documentTypeId: number;
  documentTypeName: string;
}

interface UploadFile {
  documentTypeId: number;
  file: File;
}

interface UploadDocumentPopupProps {
  isOpen: boolean;
  onClose: () => void;
  transactionId: number;
  // fileTypeArray: FileType[];
  filterDocumentTypes: DocumentType[];
}

const UploadDocumentPopup: React.FC<UploadDocumentPopupProps> = ({
  isOpen,
  onClose,
  transactionId,
  // fileTypeArray,
  filterDocumentTypes,
}) => {
  const [selectedFileTypeId, setSelectedFileTypeId] = useState<number>(0);
  const [uploadedFile, setUploadedFile] = useState<UploadFile | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { mutateAsync: uploadDocumentType } = useUploadDocumentType();
  const [errorFields, setErrorFields] = useState<string[]>([]);

  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedFileTypeId(parseInt(event.target.value));
  };

  const handleFileUpload = (
    e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>,
  ) => {
    let files: FileList | null = null;
    if (e.type === 'change') {
      files = (e as React.ChangeEvent<HTMLInputElement>).target.files;
    } else if (e.type === 'drop') {
      files = (e as React.DragEvent<HTMLDivElement>).dataTransfer.files;
    }
    if (files && files.length > 0) {
      const file = files[0];
      if (selectedFileTypeId === 0) {
        setErrorFields([...errorFields, 'filetype']);
      }

      if (file) {
        setUploadedFile({
          documentTypeId: selectedFileTypeId,
          file: file,
        });
      }
    }
  };

  const removeUploadedFile = () => {
    setUploadedFile(null);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (selectedFileTypeId === 0) {
        setErrorFields([...errorFields, 'filetype']);
        setIsSubmitting(false);
        return;
      } 
      if (uploadedFile) {
        const uploadResponse = await uploadDocumentType({
          file: uploadedFile.file,
          transactionId: transactionId,
          documentTypeId: selectedFileTypeId,
        });

        console.log('API response:', uploadResponse.data);
        console.log('API status:', uploadResponse.status);
        if (uploadResponse.status === 200) {
          setSelectedFileTypeId(0);
          setUploadedFile(null);
          onClose();
          // window.location.reload();
        }
      }
    } catch (error) {
      console.error('Error submitting data:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload Document</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isRequired isInvalid={errorFields.includes('filetype')}>
            <FormLabel fontSize="12px">Form</FormLabel>
            <Select
              name="filetype"
              value={selectedFileTypeId}
              onChange={handleOptionChange}
              placeholder="Select Form Type"
            >
              {filterDocumentTypes.map((docType) => (
                <option
                  key={docType.documentTypeId}
                  value={docType.documentTypeId}
                  file-type-name={docType.documentTypeName}
                >
                  {docType.documentTypeName}
                </option>
              ))}
            </Select>
            <FormErrorMessage>Select Form Type</FormErrorMessage>
            </FormControl>
          <Dropzone
            onFileChange={(e) => handleFileUpload(e)}
            onRemoveFile={removeUploadedFile}
            dropFile={uploadedFile?.file ?? null}
          />
        </ModalBody>
        <ModalFooter display="flex" justifyContent="center">
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            backgroundColor="#3DA42F"
            padding="15px 50px"
            borderRadius="50px"
            height="51px"
            width="200px"
            color="#FFFFFF"
            isLoading={isSubmitting}
            loadingText="Submitting"
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UploadDocumentPopup;
