import React, { useState } from 'react';
import {
  Flex,
  Button,
  Icon,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Heading,
  TableContainer,
} from '@chakra-ui/react';
import { MdAdd } from 'react-icons/md';
import TenantModal from '../../components/Tenant/TenantModal';
import { useFetchTenants } from '../../hooks/useTenant';
import { Tenant } from '../../types/Tenant';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import SearchInput, { filterArrayByTerm } from '../../components/Search';

export const PortalTenants: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(15);
  const { data: getTenants } = useFetchTenants();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleClick = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleModalSubmit = () => {
    console.log('wow');
  };

  const filteredTenants = filterArrayByTerm(getTenants ?? [], searchTerm, [
    'tenantName',
    'tenantDomain',
    'tenantContactEmail',
    'tenantPhone',
  ]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredTenants.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredTenants.length / itemsPerPage);

  const handleTenantClick = (selectedTenant: Tenant) => {
    // console.log("Tenant clicked:", selectedTenant);
    navigate(`/portal/tenants/${selectedTenant.tenantId}`, {
      state: { tenantId: selectedTenant.tenantId },
    });
  };

  return getTenants ? (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        paddingY={[4, 4]}
        paddingX={[4, 8]}
      >
        <Heading fontFamily="DM Sans Variable" fontSize="34px" fontWeight="700">
          Tenants
        </Heading>
      </Flex>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        paddingY={[4, 6]}
        paddingX={[4, 8]}
      >
        <SearchInput
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
          placeholder="Search Tenants"
        />
        <Button
          leftIcon={<Icon as={MdAdd} />}
          bg="rgb(61, 164, 47)"
          color="white"
          _hover={{ bg: 'rgb(51, 144, 37)' }}
          width={['100%', '20em']}
          height="3em"
          padding={['0.75em', '0.75em 2em']}
          gap="0.25em"
          borderRadius="2em"
          onClick={handleClick}
        >
          <Text fontSize={['md', 'lg']} fontWeight="bold">
            Create New Tenant
          </Text>
        </Button>
      </Flex>
      <Box paddingY={4} paddingX={[4, 8]}>
        <TableContainer>
          <Table variant="unstyled">
            <Thead borderBottomWidth="2px">
              <Tr>
                <Th
                  fontSize="18px"
                  fontFamily="DM Sans Variable"
                  textTransform="initial"
                >
                  Tenant Name
                </Th>
                <Th
                  fontSize="18px"
                  fontFamily="DM Sans Variable"
                  textTransform="initial"
                >
                  Tenant Domain
                </Th>
                <Th
                  fontSize="18px"
                  fontFamily="DM Sans Variable"
                  textTransform="initial"
                >
                  Tenant Contact
                </Th>
                <Th
                  fontSize="18px"
                  fontFamily="DM Sans Variable"
                  textTransform="initial"
                >
                  Tenant Email
                </Th>
                <Th
                  fontSize="18px"
                  fontFamily="DM Sans Variable"
                  textTransform="initial"
                >
                  Tenant Type
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentItems.map((tenant, index) => (
                <Tr key={index}>
                  <Td
                    onClick={() => handleTenantClick(tenant)}
                    style={{ cursor: 'pointer' }}
                    _hover={{ color: '#0076B3' }}
                    textDecoration={'underline'}
                    textTransform="capitalize"
                    fontWeight={'bold'}
                    textColor={'#0076B3'}
                  >
                    {tenant.tenantName}
                  </Td>
                  <Td>{tenant.tenantDomain}</Td>
                  <Td>{tenant.tenantContactName}</Td>
                  <Td>{tenant.tenantContactEmail}</Td>
                  <Td>{tenant.tenantTypeName}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <Flex justifyContent="center" marginY={4}>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </Flex>
      </Box>

      <TenantModal
        isOpen={isModalOpen}
        onClose={handleClose}
        onSubmit={handleModalSubmit}
      />
    </>
  ) : null;
};

export default PortalTenants;
