import { axiosClient } from '../config/axiosClient';
import { CreateUser, UpdateUser, User } from '../../types/User';
import { AxiosError, AxiosRequestConfig } from 'axios';

export const getUserById = async (userId: string) => {
  if (!userId) {
    throw new Error('Invalid userId');
  }
  const url = '/User/GetUserById';
  const config: AxiosRequestConfig = {
    params: {
      userId,
    },
  };
  const response = await axiosClient.get(url, config);
  return response.data as User;
};

export const getUsersByTenantId = async (tenantId: number) => {
  const url = '/User/GetUsersByTenant';
  const config: AxiosRequestConfig = {
    params: {
      tenantId,
    },
  };
  const response = await axiosClient.get(url, config);
  return response.data as User[];
};

export const updateUserById = async (userId: string, userData: UpdateUser) => {
  const url = '/User/UpdateUser';
  const config: AxiosRequestConfig = {
    params: {
      userId,
    },
  };
  const response = await axiosClient.put(url, userData, config);
  return response;
};

export const createUser = async (user: CreateUser) => {
  const url = '/User/CreateUser';
  const response = await axiosClient.post(url, user);
  return response;
};

export const updateUserAccess = async (userId: string, access: string) => {
  const config: AxiosRequestConfig = {
    method: 'put',
    url: `/User/UserAccess/${userId}/${access}`,
  };

  const response = await axiosClient(config);
  return response.data;
};
