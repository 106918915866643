import { AxiosRequestConfig } from "axios";
import { axiosClient } from "../config/axiosClient";
import { CreateNotification, Notification } from "../../types/Notification";

export const getCityNotifications = async (cityTenantId: number) => {
  const url = '/Notification/GetCityNotifications';
  const config: AxiosRequestConfig = {
    params: {
      cityTenantId,
    },
    headers: {
      accept: '*/*',
    },
  };
  const response = await axiosClient.get(url, config);
  return response.data as Notification[];
};

export const getTitleNotifications = async (titleTenantId: number) => {
  const url = '/Notification/GetTitleNotifications';
  const config: AxiosRequestConfig = {
    params: {
      titleTenantId,
    },
    headers: {
      accept: '*/*',
    },
  };
  const response = await axiosClient.get(url, config);
  return response.data as Notification[];
};

export const AddDashboardNotification = async (notification: CreateNotification) => {
  const url = '/Notification/AddDashboardNotification';
  const response = await axiosClient.post(url, notification);
  return response;
};