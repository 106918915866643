import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Box,
  SimpleGrid,
  Flex,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useForm, FieldError, Merge, FieldErrorsImpl } from 'react-hook-form';
import { useUpdateUser } from '../hooks/useUser';
import { User } from '../types/User';

interface UserDetailPopupProps {
  isOpen: boolean;
  onClose: () => void;
  userData: User;
}

const UserDetailModal: React.FC<UserDetailPopupProps> = ({
  isOpen,
  onClose,
  userData,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const updateTenantMutation = useUpdateUser();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const { email, firstName, lastName, phoneNumber } = userData;
      reset({ email, firstName, lastName, phoneNumber });
    }
  }, [isOpen, userData, reset]);

  const handleFormSubmit = async (data: any) => {
    const { email, firstName, lastName, phoneNumber } = data;
    const filteredData = { email, firstName, lastName, phoneNumber };
    console.log('Submitted data:', filteredData);
    setIsSubmitting(true);
    try {
      await updateTenantMutation.mutateAsync({
        userId: userData.userId,
        userData: { ...filteredData },
      });
      // Reset form fields and close modal after successful API call
      reset({ email: '', firstName: '', lastName: '', phoneNumber: '' });
      onClose();
    } catch (error) {
      console.error('Error updating UserData:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleModalClose = () => {
    reset({ email: '', firstName: '', lastName: '', phoneNumber: '' }); // Reset form fields
    onClose(); // Close modal
  };

  const isFieldError = (error: any): error is FieldError => {
    return error && typeof error.message === 'string';
  };

  const getErrorMessage = (
    error: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined,
  ): React.ReactNode => {
    if (isFieldError(error)) {
      return error.message;
    }
    return null;
  };

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>User Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <SimpleGrid columns={1} spacing={4}>
                <FormControl isInvalid={!!errors.email}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    disabled
                    {...register('email', {
                      required: 'Email is required',
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message: 'Invalid email address',
                      },
                    })}
                  />
                  <FormErrorMessage>
                    {getErrorMessage(errors.email)}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.firstName}>
                  <FormLabel>First Name</FormLabel>
                  <Input
                    {...register('firstName', {
                      required: 'First Name is required',
                    })}
                  />
                  <FormErrorMessage>
                    {getErrorMessage(errors.firstName)}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.lastName}>
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    {...register('lastName', {
                      required: 'Last Name is required',
                    })}
                  />
                  <FormErrorMessage>
                    {getErrorMessage(errors.lastName)}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.phoneNumber}>
                  <FormLabel>Phone</FormLabel>
                  <Input
                    {...register('phoneNumber', {
                      required: 'Phone number is required',
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: 'Invalid phone number',
                      },
                    })}
                  />
                  <FormErrorMessage>
                    {getErrorMessage(errors.phoneNumber)}
                  </FormErrorMessage>
                </FormControl>
              </SimpleGrid>
              <ModalFooter>
                <Flex justifyContent="flex-end" width="100%">
                  <Button colorScheme="blue" mr={3}>
                    Reset Password
                  </Button>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    type="submit"
                    isLoading={isSubmitting}
                  >
                    Update
                  </Button>
                  <Button colorScheme="red" mr={3} onClick={handleModalClose}>
                    Close
                  </Button>
                </Flex>
              </ModalFooter>
            </form>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UserDetailModal;
