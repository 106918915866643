import React from 'react';
import { Box, Button, Flex, Input, Text } from '@chakra-ui/react';
import { formatDateWithTime } from '../utils/DateUtils';

interface CommentSectionProps {
  // transactionId: string;
  handleCommentChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCommentSubmit: () => void;
  setComment: React.Dispatch<React.SetStateAction<string>>;
  comment: string;
  createdComments: any[];
  transactionData: any;
}

const CommentSection: React.FC<CommentSectionProps> = ({
  handleCommentChange,
  handleCommentSubmit,
  setComment,
  comment,
  createdComments,
  transactionData,
}) => {
  const isCompleted =
    transactionData.length > 0 &&
    transactionData[0].currentStatus === 'Completed';
  const sortComment = createdComments.sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);

    return dateB.getTime() - dateA.getTime();
  });

  return (
    <>
      <Box data-group="true" paddingY={4} paddingX={[4, 8]}>
        <Flex direction="column" marginBottom="20px">
          <Input
            placeholder="Add comments"
            width="100%"
            height="60px"
            borderRadius="20px"
            borderWidth="1px"
            padding="20px"
            borderColor="#CACACA"
            background={'#FFFFFF'}
            onChange={handleCommentChange}
            value={comment}
            isDisabled={isCompleted}
          />
        </Flex>

        <Flex
          direction="row"
          width="256px"
          height="41px"
          gap="30px"
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Button
            width="220px"
            height="41px"
            borderRadius="50px"
            padding="10px 20px"
            backgroundColor="#3DA42F"
            colorScheme="green"
            color="#FFFFFF"
            fontSize="16px"
            lineHeight="20.83px"
            letterSpacing="-2%"
            // disabled={comment.trim() === ''}
            onClick={handleCommentSubmit}
            isDisabled={isCompleted}
          >
            Submit Comment
          </Button>
          <Button
            width="100px"
            height="41px"
            borderRadius="50px"
            padding="10px 20px"
            disabled={comment.trim() === ''}
            color="#000000"
            fontSize="16px"
            lineHeight="20.83px"
            letterSpacing="-2%"
            fontWeight="700"
            onClick={() => setComment('')}
          >
            Clear
          </Button>
        </Flex>
      </Box>
      <>
        {createdComments.map((comment, index) => (
          <Box
            key={index}
            borderWidth="1px"
            background="white"
            borderRadius="20px"
            padding="20px"
            marginBottom={{ base: '20px', md: '40px' }}
            marginLeft={{ base: '0', md: '28px' }}
            marginRight={{ base: '0', md: '28px' }}
          >
            <Flex
              direction="row"
              width="376px"
              height="26px"
              gap="30px"
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Text
                textAlign="center"
                fontFamily="DM Sans Variable"
                fontWeight="700"
                fontSize="20px"
                lineHeight="26.04px"
                letterSpacing="-2%"
                color="#000000"
              >
                {comment.createdBy}
              </Text>
              <Text
                textAlign="center"
                fontFamily="DM Sans Variable"
                fontWeight="400"
                fontSize="16px"
                lineHeight="20.83px"
                letterSpacing="-2%"
                color="#000000"
              >
                {formatDateWithTime(comment.createdAt)}
              </Text>
            </Flex>

            <Flex
              direction="column"
              fontFamily="DM Sans Variable"
              fontWeight="400"
              fontSize="16px"
              lineHeight="20.83px"
              letterSpacing="-2%"
              color="#000000"
            >
              {comment.comment}
            </Flex>
          </Box>
        ))}
      </>
    </>
  );
};

export default CommentSection;
