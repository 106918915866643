import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AddDashboardNotification, getCityNotifications, getTitleNotifications } from "../services/api/notificationService";
import { CreateNotification } from "../types/Notification";

export const useFetchCityNotifications = (cityTenantId: number) => {
  return useQuery({
    queryKey: ['getCityNotifications', cityTenantId],
    queryFn: () => getCityNotifications(cityTenantId),
  });
};

export const useFetchTitleNotifications = (titleTenantId: number) => {
  return useQuery({
    queryKey: ['getTitleNotifications', titleTenantId],
    queryFn: () => getTitleNotifications(titleTenantId),
  });
};

export const useCreateNotification = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (notification: CreateNotification) => AddDashboardNotification(notification),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getTitleNotifications'] });
      queryClient.invalidateQueries({ queryKey: ['getCityNotifications'] });
    },
  });
};