export const formatDateNumber = (dateString: string) => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      timeZone: timeZone,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    return date.toLocaleDateString("en-US", options);
  };

  export const formatDate = (timestamp: string) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      // hour: 'numeric',
      // minute: 'numeric',
      // hour12: true,
    });
    return formattedDate;
  };

  export const formatDateWithTime = (timestamp: string) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return formattedDate;
  };

  export const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month: string | number = today.getMonth() + 1;
    let day: string | number = today.getDate();
    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }

    return `${year}-${month}-${day}`;
  };
  