
import { Flex, List, ListItem, Text, useColorModeValue, Image } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import eHomestead from '../assets/eHomestead.png';
import Facebook from '../assets/Facebook.svg';
import Instagram from '../assets/Instagram.svg';
import LinkedIn from '../assets/LinkedIn.svg';
import Twitter from '../assets/Twitter.svg';

export default function Footer() {
  const textColor = useColorModeValue('gray.400', 'white');

  return (
    <Flex
      as="footer"
      bg="#ffffff"
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      padding="30px 40px 20px 40px"
      gap="10px"
      // py="20px"
      // px="40px"
      mt="auto"
    >
      <Image src={eHomestead} w="178px" h="75px" mb="30px" />
      {/* <Box> */}
        <List display="flex">
          <ListItem mx="20px">
            <RouterLink to="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <Image src={Facebook} style={{ width: '24px', height: '24px' }} alt="Facebook" />
            </RouterLink>
          </ListItem>
          <ListItem mx="20px">
            <RouterLink to="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
              <Image src={Twitter} style={{ width: '24px', height: '24px' }} alt="Twitter" />
            </RouterLink>
          </ListItem>
          <ListItem mx="20px">
            <RouterLink to="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <Image src={LinkedIn} style={{ width: '24px', height: '24px' }} alt="LinkedIn" />
            </RouterLink>
          </ListItem>
          <ListItem mx="20px">
            <RouterLink to="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <Image src={Instagram} style={{ width: '24px', height: '24px' }} alt="Instagram" />
            </RouterLink>
          </ListItem>
        </List>

        <Text color={textColor} textAlign="center" mb="20px">
          &copy; {new Date().getFullYear()} eHomestead File. All rights reserved.
        </Text>
      {/* </Box> */}
    </Flex>
  );
}
