import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  createUser,
  getUserById,
  getUsersByTenantId,
  updateUserAccess,
  updateUserById,
} from '../services/api/userService';
import { UpdateUser, CreateUser } from '../types/User';
import { AxiosError } from 'axios';

export const useFetchUser = (userId: string) => {
  return useQuery({
    queryKey: ['getUserById', userId],
    queryFn: () => getUserById(userId),
    enabled: !!userId, // Only run the query if userId is not empty
  });
};

export const useFetchUsersByTenantId = (tenantId: number) => {
  return useQuery({
    queryKey: ['getUsersByTenantId', { tenantId }],
    queryFn: () => getUsersByTenantId(tenantId),
    throwOnError(error: AxiosError, query) {
      if (error.response?.status === 404) {
        return false;
      }
      throw error;
    },
  });
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { userId: string; userData: UpdateUser }) =>
      updateUserById(data.userId, data.userData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getUserById'] });
      queryClient.invalidateQueries({ queryKey: ['getUsersByTenantId'] });
    },
  });
};

export const useCreateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { userData: CreateUser }) => createUser(data.userData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getUsersByTenantId'] });
      queryClient.invalidateQueries({ queryKey: ['getUserById'] });
    },
    onError: (error: AxiosError, variables, context) => {
      console.error(
        'error creating user',
        error.response?.data,
        +variables,
        error,
      );
    },
  });
};

export const useUpdateUserAccess = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ userId, access }: { userId: string; access: string }) =>
      updateUserAccess(userId, access),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getUsersByTenantId'] });
      queryClient.invalidateQueries({ queryKey: ['getUserById'] });
    },
  });
};
