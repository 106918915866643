import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
import { Dropzone } from '../../../components/Dropzone';

interface UploadNewFileProps {
  isOpen: boolean;
  onClose: () => void;
  handleUpload: (file: File) => void;
}

const UploadNewFile: React.FC<UploadNewFileProps> = ({
  isOpen,
  onClose,
  handleUpload,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>,
  ) => {
    let files: FileList | null = null;
    if (e.type === 'change') {
      files = (e as React.ChangeEvent<HTMLInputElement>).target.files;
    } else if (e.type === 'drop') {
      files = (e as React.DragEvent<HTMLDivElement>).dataTransfer.files;
    }
    if (files && files.length > 0) {
      setSelectedFile(files[0]);
    }
  };

  const handleSubmit = () => {
    if (selectedFile) {
      handleUpload(selectedFile);
      setSelectedFile(null);
    }
    onClose();
  };

  const removeUploadedFile = () => {
    setSelectedFile(null);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload New Document</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Dropzone
            onFileChange={(e) => handleFileChange(e)}
            onRemoveFile={removeUploadedFile}
            dropFile={selectedFile}
          />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            Upload
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UploadNewFile;
