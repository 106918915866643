import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Box,
} from '@chakra-ui/react';
import { useFetchDocumentsHistory } from '../hooks/useFiles';
import { formatDate } from '../utils/DateUtils';

type DocumentHistoryAccordionProps = {
  children: React.ReactNode;
  transactionId: number;
  documentTypeId: number;
};
type DocumentHistory = {
  documentHistoryId: number;
  documentTypeId: number;
  modifiedAt: string;
  message: string;
};

export const DocumentHistoryAccordion = ({
  children,
  transactionId,
  documentTypeId,
}: DocumentHistoryAccordionProps) => {
  const { data: documentsHistory } = useFetchDocumentsHistory(transactionId);
  const documentHistoryForId = documentsHistory?.filter(
    (doc) => doc.documentTypeId === documentTypeId,
  );

  return (
    <Accordion allowToggle>
      <AccordionItem border="none" borderTop={'1px solid #D0D0D0'}>
        <AccordionPanel pb={4} borderBottom={'1px solid #D0D0D0'}>
          {documentsHistory
            ? documentHistoryForId?.map((document) => (
                <Box
                  key={document.documentHistoryId}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Text
                    fontFamily="DM Sans Variable"
                    fontWeight="500"
                    fontSize="16px"
                    lineHeight="20.83px"
                    letterSpacing="-0.02em"
                    color="grey"
                  >
                    {formatDate(document.modifiedAt)}&nbsp;&nbsp;&nbsp;
                  </Text>
                  <Text
                    fontFamily="DM Sans Variable"
                    fontWeight="500"
                    fontSize="16px"
                    lineHeight="20.83px"
                    letterSpacing="-0.02em"
                    color="black"
                  >
                    {document.message}
                  </Text>
                </Box>
              ))
            : 'No history found'}
        </AccordionPanel>
        <Box display="flex" flexDirection="row">
          <AccordionButton width={'12%'} _hover={{ bgColor: 'white' }}>
            <Text
              fontFamily="DM Sans Variable"
              fontWeight="500"
              fontSize="16px"
              lineHeight="20.83px"
              letterSpacing="-0.02em"
              color="#0076B3"
              cursor="pointer"
              // _hover={{ textDecoration: 'underline' }}
            >
              Documents History
            </Text>
            <AccordionIcon />
          </AccordionButton>
          <Box
            flex="1"
            width="50%"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            {children}
          </Box>
        </Box>
      </AccordionItem>
    </Accordion>
  );
};
