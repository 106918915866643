import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  createTransaction,
  fetchTransactionById,
  getCityTransactions,
  getTitleTransactions,
  updateTransactionStatus,
} from '../services/api/transactionService';
import { AxiosError } from 'axios';

// export const useFetchTransactions = () => {
//   return useQuery({
//     queryKey: ['getTransactions'],
//     queryFn: fetchTransactions,
//     // staleTime: 60000,
//     // cacheTime: 0,
//   });
// };

export const useFetchTransactionById = (id: number) => {
  return useQuery({
    queryKey: ['getTransactionById', { id }],
    queryFn: () => fetchTransactionById(id),
  });
};

export const useCreateTransaction = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createTransaction,
    onSuccess: () => {
      // queryClient.invalidateQueries({ queryKey: ['getTransactions'] });
      queryClient.invalidateQueries({ queryKey: ['getTitleTransactions'] });
      queryClient.invalidateQueries({ queryKey: ['getCityTransactions'] });
    },
    onError: (error: AxiosError, variables) => {
      console.error(
        'error creating transaction',
        error.response?.data,
        variables,
        error,
      );
    },
  });
};

export const useUpdateTransactionStatus = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      transactionId,
      toStatus,
    }: {
      transactionId: number;
      toStatus: string;
    }) => updateTransactionStatus(transactionId, toStatus),
    onSuccess: () => {
      // queryClient.invalidateQueries({ queryKey: ['getTransactions'] });
      queryClient.invalidateQueries({ queryKey: ['getTitleTransactions'] });
      queryClient.invalidateQueries({ queryKey: ['getCityTransactions'] });
      queryClient.invalidateQueries({ queryKey: ['getTransactionById'] });
    },
  });
};
export const useGetTitleTransactions = (id: number) => {
  return useQuery({
    queryKey: ['getTitleTransactions', { id }],
    queryFn: () => getTitleTransactions(id),
    throwOnError(error: AxiosError, query) {
      if (error.response?.status === 404) {
        return false;
      }

      throw error;
    },
  });
};

export const useGetCityTransactions = (id: number | null) => {
  return useQuery({
    queryKey: ['getCityTransactions', { id }],
    queryFn: () => getCityTransactions(id),
    throwOnError(error: AxiosError, query) {
      if (error.response?.status === 404) {
        return false;
      }

      throw error;
    },
  });
};
