import { AxiosRequestConfig } from "axios";
import { DocumentStatus } from "../../types/DocumentStatus";
import { StatusTransition } from "../../types/StatusTransition";
import { axiosClient } from "../config/axiosClient";
import { City } from "../../types/City";

export const fetchStaticData = async () => {
    const url = '/StaticData/GetDataToCreateTransaction';
    const response = await axiosClient.get(url);
    return response.data;
};

export const fetchDocumentStatuses = async () => {
    const url = '/StaticData/GetDocumentStatuses';
    const response = await axiosClient.get(url);
    return response.data as DocumentStatus[];
};

export const fetchStatusTransitions = async () => {
    const url = '/StaticData/GetStatusTransitions';
    const response = await axiosClient.get(url);
    return response.data as StatusTransition[];
};

export const fetchCities = async () => {
    const url = '/StaticData/GetCities';
    const response = await axiosClient.get(url);
    return response.data.cities as City[];
};

export const fetchCitiesByTenantId = async (tenantId: number | null) => {
    const url = '/StaticData/GetCitiesByTenantId';
    const config: AxiosRequestConfig = {
        params: {
            tenantId,
        },
    };
    const response = await axiosClient.get(url, config);;
    return response.data.cities as City[];
};

export const updateTenantIdForCities = async (cityIds: number[], tenantId: number) => {
    const url = '/StaticData/UpdateTenantIdForCities';
    const response = await axiosClient.put(url, {cityIds, tenantId});
    return response.data;
  };