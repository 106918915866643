import React, { useState, useEffect } from 'react';
import {
  Flex,
  Button,
  Image,
  Text,
  Box,
  RadioGroup,
  Radio,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Alert,
  CloseButton,
  AlertTitle,
  AlertDescription,
  Heading,
} from '@chakra-ui/react';
import { useTransaction } from '../../Context/TransactionContext';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import approved from '../../assets/correct.svg';
import submitted from '../../assets/Unopened.svg';
import reviewed from '../../assets/Inprogress.svg';
import rejected from '../../assets/Rejected.svg';
import { DocumentIndicators } from '../../components/DocumentIndicators';
import { TableHeaders } from '../../components/TableHeaders';
import viewTransaction from '../../utils/viewTransaction';
import { formatDate } from '../../utils/DateUtils';
import { useGetCityTransactions } from '../../hooks/useTransaction';
import { Transaction } from '../../types/Transaction';
import Pagination from '../../components/Pagination';
import SearchInput from '../../components/Search';
import { useUserContext } from '../../Context/UserContext';
import { DocumentStatusIds } from '../../utils/Statuses';
import * as TransactionStatus from '../../constants/TransactionStatus';

const CityDashboard = () => {
  const userInfo = useUserContext();
  const [transactionsData, setTransactionsData] = useState<Transaction[]>([]);
  const [filteredData, setFilteredData] = useState<Transaction[]>([]);
  const [selectedStatus, setSelectedStatus] = useState(TransactionStatus.All);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const { setTransaction } = useTransaction();
  const tenantId = userInfo.tenantId;
  const {
    data: transactions = [],
    isFetching,
    isLoading,
    isError,
  } = useGetCityTransactions(tenantId);
  const {
    rejectedStatusId,
    approvedStatusId,
    reviewedStatusId,
    submittedStatusId,
  } = DocumentStatusIds();

  useEffect(() => {
    if (transactions.length > 0) {
      const sortedTransactions = transactions.slice().sort((a, b) => {
        if (b.transactionId === undefined) return -1;
        if (a.transactionId === undefined) return 1;
        return b.transactionId - a.transactionId;
      });
      setTransactionsData(sortedTransactions);
      filterTransactions(sortedTransactions, selectedStatus, searchTerm);
    }
  }, [transactions, selectedStatus, searchTerm]);
  const searchInObject = (obj: Transaction, term: string) => {
    const lowerCaseTerm = term.toLowerCase();
    return Object.values(obj).some((value) => {
      if (value) {
        return value.toString().toLowerCase().includes(lowerCaseTerm);
      }
      return false;
    });
  };

  const filterTransactions = (
    transactions: Transaction[],
    status: string,
    term: string,
  ) => {
    const filtered = transactions.filter(
      (item) =>
        searchInObject(item, term) &&
        (status === TransactionStatus.All ||
          item.currentStatus.toLowerCase() === status.toLowerCase() ||
          (status === TransactionStatus.NotCompleted &&
            (item.currentStatus === TransactionStatus.InProgress ||
              item.currentStatus === TransactionStatus.New))),
    );
    setFilteredData(filtered);
  };
  // const isMobileView = useBreakpointValue({ base: true, md: false });

  const handleStatusChange = (newStatus: string) => {
    setSelectedStatus(newStatus);
    filterTransactions(transactionsData, newStatus, searchTerm);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    filterTransactions(transactionsData, selectedStatus, event.target.value);
  };

  const handleViewDetails = (item: Transaction) => {
    setTransaction(item);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const AlertStat = ({
    expiryDt,
    closingDate,
  }: {
    expiryDt: string;
    closingDate: string;
  }) => {
    const date1: any = new Date(expiryDt);
    const currentDate = new Date();
    const diffMilliseconds = date1.getTime() - currentDate.getTime();
    // Total number of days to expire
    const DaysToExpire = Math.ceil(diffMilliseconds / (1000 * 60 * 60 * 24));
    const [showAlert, setShowAlert] = useState(true);
    const handleCloseAlert = () => {
      setShowAlert(false);
    };
    if (DaysToExpire <= 7) {
      return (
        <>
          {showAlert && (
            <Alert status="warning">
              <WarningTwoIcon
                color="#F7C752"
                style={{
                  width: '31.26px',
                  height: '27px',
                  top: ' 2.84px',
                  left: '1.42px',
                }}
              />
              <AlertTitle>Expiring Soon!</AlertTitle>
              <AlertDescription>
                The Transaction will expire in {DaysToExpire} days.
              </AlertDescription>
              <CloseButton
                position="absolute"
                right="8px"
                top="8px"
                onClick={handleCloseAlert}
              />
            </Alert>
          )}
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        paddingY={[4, 8]}
        paddingX={[4, 8]}
      >
        <Heading fontFamily="DM Sans Variable" fontSize="34px" fontWeight="700">
          Dashboard
        </Heading>
      </Flex>
      <Box data-group="true" paddingY={2} paddingX={[4, 8]}>
        <SearchInput
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
          placeholder="Search Transactions"
        />
      </Box>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        paddingY={4}
        paddingX={[4, 8]}
      >
        <Box flex="1">
          <fieldset role="group">
            <legend
              style={{
                fontSize: '12px',
                fontWeight: 'bold',
                paddingBottom: '10px',
              }}
            >
              Filter by Status
            </legend>
            <RadioGroup onChange={handleStatusChange} value={selectedStatus}>
              <Stack spacing={5} direction="row">
                {TransactionStatus.FilterMap.map((status) => (
                  <Radio
                    key={status.key}
                    value={status.value}
                    borderColor="#3DBA7E"
                    colorScheme="green"
                  >
                    <Text
                      fontWeight="500"
                      fontSize="14px"
                      letterSpacing="-0.02em"
                      textColor="#848181"
                    >
                      {status.value}
                    </Text>
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          </fieldset>
        </Box>
        <Box flex="1" textAlign="right">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </Box>
      </Flex>
      <Box margin="0">
        {isLoading || isFetching ? (
          <Text textAlign="center" fontSize="lg" fontWeight="bold">
            Loading...
          </Text>
        ) : isError ? (
          <Text textAlign="center" fontSize="lg" fontWeight="bold">
            Error loading transactions.
          </Text>
        ) : currentItems.length === 0 ? (
          <Text textAlign="center" fontSize="lg" fontWeight="bold">
            No records found.
          </Text>
        ) : (
          currentItems.map((item, index) => (
            <Box
              key={index}
              borderWidth="1px"
              background="white"
              borderRadius="20px"
              padding="20px"
              marginBottom={{ base: '20px', md: '20px' }}
              marginLeft={{ base: '0', md: '28px' }}
              marginRight={{ base: '0', md: '28px' }}
            >
              {item.currentStatus === 'New' ||
              item.currentStatus === 'In Progress' ? (
                <AlertStat
                  expiryDt={item.expiryDt ?? ''}
                  closingDate={item.closingDate}
                />
              ) : (
                ''
              )}
              <Table variant="simple" size="sm">
                <Thead style={{ whiteSpace: 'nowrap' }}>
                  <Tr>
                    {TableHeaders.map((header, index) => (
                      <Th
                        key={index}
                        borderBottom="none"
                        textAlign="left"
                        textTransform="initial"
                        textColor="#000000"
                        fontWeight="700"
                        fontSize="14px"
                        letterSpacing="-0.02em"
                        // lineHeight={{ base: '18.23px', md: '20.16px' }}
                        // lineHeight="20.83px"
                        // fontFamily="DM Sans Variable"
                      >
                        {header.label}
                      </Th>
                    ))}
                    <Th borderBottom="none" textAlign="right">
                      <RouterLink
                        to={`/city/viewtransaction/${item.transactionId}`}
                      >
                        <Button
                          style={viewTransaction}
                          onClick={() => handleViewDetails(item)}
                        >
                          View Transaction Details
                        </Button>
                      </RouterLink>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr
                    border="none"
                    textColor="#848181"
                    fontWeight="500"
                    letterSpacing="-0.02em"
                  >
                    <Td borderBottom="none">
                      <Badge
                        textTransform="initial"
                        textAlign="center"
                        fontSize="12px"
                        fontWeight="700"
                        borderRadius="full"
                        letterSpacing="-0.02em"
                        padding="2px 10px 2px 10px"
                        // px="2"
                        color={
                          item.currentStatus === TransactionStatus.New
                            ? '#3DA42F'
                            : '#FFFFFF'
                        }
                        backgroundColor={
                          item.currentStatus === TransactionStatus.Completed
                            ? '#3DA42F'
                            : item.currentStatus ===
                              TransactionStatus.InProgress
                            ? '#0076B3'
                            : item.currentStatus === TransactionStatus.New
                            ? '#FFFFFF'
                            : 'whitesmoke'
                        }
                        border={
                          item.currentStatus === TransactionStatus.New
                            ? '1px solid #3DA42F'
                            : '2px'
                        }
                      >
                        {item.currentStatus}
                      </Badge>
                    </Td>
                    <Td borderBottom="none" fontSize="16px">
                      <Text>{item.address}</Text>
                      <Text>
                        {item.city}, {item.state} {item.zip}
                      </Text>
                    </Td>
                    <Td borderBottom="none" fontSize="14px">
                      {formatDate(item.closingDate)}
                    </Td>
                    <Td borderBottom="none" fontSize="14px">
                      {formatDate(item.expiryDt ?? '')}
                    </Td>
                    <Td borderBottom="none" fontSize="14px">
                      {item.clientName}
                    </Td>
                    <Td borderBottom="none" fontSize="14px">
                      {item.clientType}
                    </Td>
                    <Td borderBottom="none" fontSize="14px">
                      {item.transactionId}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
              <Box borderBottom="1px solid #E2E8F0" marginTop="20px" />
              <Flex
                direction={{ base: 'column', md: 'row' }}
                alignItems={{ base: 'flex-start', md: 'center' }}
                paddingTop="20px"
              >
                <Box
                  marginBottom={{ base: '15px', md: 0 }}
                  marginRight={{ base: '0', md: '20px' }}
                >
                  <Text
                    fontSize={{ base: '14px', md: 'lg' }}
                    fontWeight="700"
                    fontFamily="DM Sans Variable"
                    lineHeight={{ base: '18.23px', md: 'normal' }}
                    letterSpacing="-0.02em"
                    marginBottom={{ base: '5px', md: 0 }}
                    marginRight={{ base: '0', md: '20px' }}
                  >
                    Documents
                  </Text>
                  <Flex
                    alignItems="center"
                    marginTop={{ base: '3px', md: '5px' }}
                  >
                    {item.transactionDocuments.length > 0 ? (
                      item.transactionDocuments.map((doc: any, key: number) => (
                        <Box
                          key={key}
                          marginRight={{ base: '8px', md: '10px' }}
                          display="flex"
                          alignItems="center"
                        >
                          <Image
                            src={
                              doc.currentStatus === submittedStatusId
                                ? submitted
                                : doc.currentStatus === reviewedStatusId
                                ? reviewed
                                : doc.currentStatus === approvedStatusId
                                ? approved
                                : doc.currentStatus === rejectedStatusId
                                ? rejected
                                : submitted
                            }
                            alt="Unopened Icon"
                            width={5}
                            height={5}
                          />
                          <Text
                            fontSize={{ base: '14px', md: 'lg' }}
                            fontWeight="500"
                            fontFamily="Plus Jakarta Sans Variable"
                            lineHeight={{ base: '18.23px', md: '20.16px' }}
                            letterSpacing="-0.02em"
                            textAlign="left"
                            color="#848181"
                            marginLeft="8px"
                          >
                            {doc.documentTypeName}
                          </Text>
                        </Box>
                      ))
                    ) : (
                      <Text>No documents found</Text>
                    )}
                  </Flex>
                </Box>
              </Flex>
            </Box>
          ))
        )}
      </Box>
      <Box paddingY={4} paddingX={[4, 8]}>
        <DocumentIndicators />
      </Box>
    </>
  );
};

export default CityDashboard;
