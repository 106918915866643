import { useState } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  Flex,
  Spacer,
  Switch, // Import Select component from Chakra UI
} from '@chakra-ui/react';
import { MdAdd } from 'react-icons/md';
import { useCreateUser, useUpdateUser } from '../../hooks/useUser';
import { CreateUser, User } from '../../types/User';
import StaffModal from './StaffModal';
import { useResetPassword } from '../../hooks/usePasswordReset';
import Pagination from '../Pagination';

const initialUser: CreateUser = {
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  tenantId: 0,
  role: {
    isAdminUser: 0,
    tenantType: '',
  },
};

interface TenantStaffProps {
  staffData: User[];
  tenantId: number;
  tenant_type: string;
  handleAccessToggle: (userId: string, isActive: number) => void;
}

const TenantStaff = ({
  staffData,
  tenantId,
  tenant_type,
  handleAccessToggle,
}: TenantStaffProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNewUser, setIsNewUser] = useState(true);
  const [selectedUser, setSelectedUser] = useState<CreateUser>(initialUser);
  const [selectedUserId, setSelectedUserId] = useState('');
  const { mutateAsync: createUser } = useCreateUser();
  const { mutateAsync: updateUser } = useUpdateUser();
  const { mutateAsync: resetPassword } = useResetPassword();

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = staffData.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(staffData.length / recordsPerPage);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleOnStaffSubmit = async (values: CreateUser) => {
    if (isNewUser) {
      await createUser(
        { userData: values },
        {
          onSuccess: () => {
            handleModalClose();
          },
        },
      );
    } else {
      await updateUser(
        { userId: selectedUserId, userData: values },
        {
          onSuccess: () => {
            handleModalClose();
          },
        },
      );
    }
  };

  const handleUserRowClick = (user: User) => {
    setIsNewUser(false);
    setSelectedUserId(user.userId);
    setSelectedUser({
      ...initialUser,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      tenantId: tenantId,
      role: {
        isAdminUser: user.isAdminUser,
        tenantType: tenant_type,
      },
    });
    handleModalOpen();
    // console.log(user);
  };

  const handleAddStaffClick = () => {
    setIsNewUser(true);
    setSelectedUserId('');
    setSelectedUser({
      ...initialUser,
      tenantId: tenantId,
      role: {
        isAdminUser: 0,
        tenantType: tenant_type,
      },
    });
    handleModalOpen();
  };

  const handleResetPassword = async () => {
    await resetPassword(selectedUserId);
  };

  return (
    <>
      <Box p={[0, 0]}>
        <Flex justify="space-between" align="center" mb={4}>
          <Spacer />
          <Flex paddingLeft={10}>
            <Button
              leftIcon={<MdAdd />}
              bg="rgb(61, 164, 47)"
              color="white"
              _hover={{ bg: 'rgb(51, 144, 37)' }}
              width={['100%', '8em']}
              height="2.5em"
              // padding={['0.75em', '0.75em 2em']}
              gap="0.25em"
              borderRadius="2em"
              onClick={handleAddStaffClick}
            >
              Add Staff
            </Button>
          </Flex>
        </Flex>
        {staffData.length > 0 ? (
          <>
            <TableContainer>
              <Table colorScheme="gray">
                <Thead borderBottomWidth="2px">
                  <Tr>
                    <Th>UserName / Email</Th>
                    <Th>Role</Th>
                    <Th>First Name</Th>
                    <Th>Last Name</Th>
                    <Th>Phone Number</Th>
                    {/* <Th>Role</Th> */}
                    <Th>Status</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {currentRecords.map((user) => (
                    <Tr key={user.userId}>
                      <Td
                        onClick={() => handleUserRowClick(user)}
                        style={{ cursor: 'pointer' }}
                        _hover={{ color: '#0076B3' }}
                        textDecoration={'underline'}
                        fontWeight={'bold'}
                        textColor={'#0076B3'}
                      >
                        {user.userName}
                      </Td>
                      <Td>{user.isAdminUser === 1 ? 'Admin' : 'Staff'}</Td>
                      <Td>{user.firstName}</Td>
                      <Td>{user.lastName}</Td>
                      <Td>{user.phoneNumber}</Td>
                      {/* <Td>{user.role}</Td> */}
                      <Td>
                        <Flex align="center">
                          <Switch
                            size="sm"
                            isChecked={user.isActive === 1}
                            onChange={() =>
                              handleAccessToggle(user.userId, user.isActive)
                            }
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
            <Flex justifyContent="center" paddingTop={4} marginY={4}>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </Flex>
          </>
        ) : null}

        <StaffModal
          isUpdate={!isNewUser}
          isOpen={isModalOpen}
          onClose={handleModalClose}
          onStaffSubmit={(values) => handleOnStaffSubmit(values)}
          userData={selectedUser}
          onResetPassword={handleResetPassword}
        />
      </Box>
    </>
  );
};

export default TenantStaff;
