import React from 'react';
import { Button, ButtonGroup } from '@chakra-ui/react';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  return (
    <ButtonGroup>
      <Button
        onClick={() => onPageChange(currentPage > 1 ? currentPage - 1 : 1)}
        isDisabled={currentPage === 1}
        fontSize="13px"
        fontWeight="600"
      >
        Prev
      </Button>
      {[...Array(totalPages)].map((_, index) => (
        <React.Fragment key={index}>
          {index === 0 ||
          index === totalPages - 1 ||
          Math.abs(currentPage - index - 1) <= 1 ? (
            <Button
              onClick={() => onPageChange(index + 1)}
              variant={currentPage === index + 1 ? 'solid' : 'outline'}
              bg={currentPage === index + 1 ? 'blue.500' : ''}
              color={currentPage === index + 1 ? 'white' : ''}
              fontSize="13px"
              fontWeight="600"
            >
              {index + 1}
            </Button>
          ) : index === 1 && currentPage > 3 ? (
            <Button fontSize="13px" fontWeight="600" isDisabled>
              {'...'}
            </Button>
          ) : index === totalPages - 2 && currentPage < totalPages - 2 ? (
            <Button fontSize="13px" fontWeight="600" isDisabled>
              {'...'}
            </Button>
          ) : null}
        </React.Fragment>
      ))}
      <Button
        fontSize="13px"
        fontWeight="600"
        onClick={() =>
          onPageChange(currentPage < totalPages ? currentPage + 1 : totalPages)
        }
        isDisabled={currentPage === totalPages}
      >
        Next
      </Button>
    </ButtonGroup>
  );
};

export default Pagination;
