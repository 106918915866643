import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useCreateTenant } from '../../hooks/useTenant';
import { CreateTenant, ManageTenantResponse } from '../../types/Tenant';
import TenantInfo from './TenantInfo';
import { useUpdateTenantIdForCities } from '../../hooks/useStaticData';

interface TenantModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const TenantModal: React.FC<TenantModalProps> = ({ isOpen, onClose }) => {
  const { reset } = useForm<CreateTenant>();
  const { mutateAsync: createTenant } = useCreateTenant();
  const { mutateAsync: updateTenantIdForCities } = useUpdateTenantIdForCities();

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleCreateTenant = async (
    values: CreateTenant,
    cityIds: number[],
  ) => {
    await createTenant(values, {
      onSuccess: async (data: ManageTenantResponse) => {
        await updateTenantIdForCities({
          cityIds: cityIds,
          tenantId: data.tenantId,
        });
      },
    });
    handleClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="2xl">
      <ModalOverlay />
      <ModalContent maxW="80%">
        <ModalHeader>Create New Tenant</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TenantInfo
            isCreate={true}
            onClickSave={({ values, selectedCityIds }) =>
              handleCreateTenant(values, selectedCityIds)
            }
            onCancel={handleClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TenantModal;
