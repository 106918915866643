import { AxiosRequestConfig } from "axios";
import { CreateTenant, ManageTenantResponse, Tenant } from "../../types/Tenant"; 
import { axiosClient } from "../config/axiosClient";


export const fetchTenants = async () => {
  const url = '/Tenant/GetTenants';
  const response = await axiosClient.get(url);
  return response.data as Tenant[];
};

export const getTenantById = async (tenantId: number) => {
  const url = '/Tenant/GetTenantById';
  const config: AxiosRequestConfig = {
    params: {
      tenantId,
    },
  };
  const response = await axiosClient.get(url, config);
  return response.data as Tenant;
};

export const createTenant = async (tenant: CreateTenant) => {
  // console.log('tenant', tenant)
  const url = '/Tenant/AddTenant';

  const response = await axiosClient.post(url, tenant);
  console.log('response', response);
  return response.data as ManageTenantResponse;
};

export const updateTenant = async (tenantId: number, tenant: CreateTenant) => {
  const url = '/Tenant/UpdateTenant';
  const config: AxiosRequestConfig = {
    params: {
      tenantId: tenantId,
    },
  };
  const response = await axiosClient.put(url, tenant, config);
  return response.data as ManageTenantResponse;
};

