import { axiosClient } from "../config/axiosClient";
import { Comment, CreateComment } from "../../types/Comment";
import { AxiosRequestConfig } from "axios";

export const fetchCommentsById = async (id: number) => {
    const url = '/Comment/GetCommentsByTransactionId';
    const config : AxiosRequestConfig = {
        params: {
            transactionId: id
        },
    };
    const response = await axiosClient.get(url, config);
    return response.data as Comment[];
};

export const createCommentById = async (Comment: CreateComment) => {
    const url = '/Comment/AddCommentByTransactionId';
    const response = await axiosClient.post(url, Comment);
    return response;
};