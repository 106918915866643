import React, { useState } from 'react';
import {
  Flex,
  Text,
  Box,
  Button,
  ButtonGroup,
  Heading,
  Icon,
  // useBreakpointValue,
  Badge,
  Table,
  Thead,
  Th,
  Tr,
  Image,
  Td,
  Tbody,
} from '@chakra-ui/react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { BsArrowLeftCircle } from 'react-icons/bs';
import approved from '../../assets/correct.svg';
import submitted from '../../assets/Unopened.svg';
import reviewed from '../../assets/Inprogress.svg';
import rejected from '../../assets/Rejected.svg';
import { formatDate } from '../../utils/DateUtils';
import { DocumentType } from '../../types/DocumentType';
import { MdAdd } from 'react-icons/md';
import UploadDocumentPopup from './components/UploadDocumentPopup';
import { handleFileDownload } from '../../utils/fileUtils';
import CommentSection from '../../components/CommentSection';
import UploadNewFile from './components/UploadNewFile';
import {
  useFetchTransactionById,
  useUpdateTransactionStatus,
} from '../../hooks/useTransaction';
import { useFetchStaticData } from '../../hooks/useStaticData';
import { useCreateComment, useFetchComments } from '../../hooks/useComment';
import {
  useDeleteDocumentType,
  useFetchDocuments,
  useUpdateDocumentStatus,
  useUpdateDocumentType,
  useUploadDocumentType,
} from '../../hooks/useFiles';
import { DocumentHistoryAccordion } from '../../components/DocumentHistoryAccordion';
import { DocumentActions } from './components/DocumentActions';
import { TransactionDocument } from '../../types/TransactionDocument';
import { DocumentStatusIds } from '../../utils/Statuses';
import { useCreateNotification } from '../../hooks/useNotification';
import { useUserContext } from '../../Context/UserContext';

const ViewTransactionDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const transactionId = parseInt(id ?? '0');
  const userInfo = useUserContext();
  // const isMobileView = useBreakpointValue({ base: true, md: false });
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isUploadNewFile, setIsUploadNewFile] = useState(false);
  const [unattachedDocumentTypes, setUnattachedDocumentTypes] = useState<
    DocumentType[]
  >([]);
  // const [attachedDocCount, setAttachedDocCount] = useState<number>(0);
  // const [allDocCount, setAllDocCount] = useState<number>(0);
  const [comment, setComment] = useState<string>('');
  const [modalSelectedDocumentId, setModalSelectedDocumentId] =
    useState<number>(0);
  const staticData = useFetchStaticData();
  const fetchTransactionById = useFetchTransactionById(parseInt(id ?? '0'));

  const fetchDocuments = useFetchDocuments(parseInt(id ?? '0'));
  const CommentsById = useFetchComments(parseInt(id ?? '0'));
  const { mutateAsync: addComment } = useCreateComment();
  const { mutateAsync: deleteDocumentType } = useDeleteDocumentType();
  const { mutateAsync: updateDocumentType } = useUpdateDocumentType();
  const { mutateAsync: updateDocumentStatus } = useUpdateDocumentStatus();
  const { mutateAsync: uploadDocumentType } = useUploadDocumentType();
  const { mutateAsync: updateTransactionStatus } = useUpdateTransactionStatus();
  const { mutateAsync: createNotification } = useCreateNotification();

  const isAllDocumentsApproved =
    fetchDocuments.data &&
    fetchDocuments.data.every(
      (doc: TransactionDocument) => doc.statusName === 'Approved',
    );

  const disableCompleteTransactionButton =
    !isAllDocumentsApproved ||
    (fetchTransactionById.data &&
    fetchTransactionById.data[0].currentStatus === 'Completed'
      ? true
      : false);

  const allDocumentTypes =
    (staticData.data?.documentTypes as DocumentType[]) ?? [];
  const otherDocTypeId = allDocumentTypes.find(
    (docType) => docType.documentTypeName.trim().toLowerCase() === 'other',
  )?.documentTypeId;

  const {
    rejectedStatusId,
    approvedStatusId,
    reviewedStatusId,
    submittedStatusId,
    reSubmittedStatusId,
    statuses,
  } = DocumentStatusIds();

  const tableHeaders = [
    'Address',
    'Date Created',
    'Expiration Date',
    'Client Name',
    // 'Title Company',
    'Client Type',
    'Transaction No.',
  ];

  const openFileUploadModal = (DocumentTypeId: number) => {
    setIsUploadNewFile(true);
    setModalSelectedDocumentId(DocumentTypeId);
  };

  const closeFileUploadModal = () => {
    setIsUploadNewFile(false);
    setModalSelectedDocumentId(0);
  };

  const handleDocumentUpload = async (file: File, fileTypeId: number) => {
    const uploadResponse = await uploadDocumentType({
      file: file,
      transactionId: parseInt(id ?? '0'),
      documentTypeId: fileTypeId,
    });
    if (uploadResponse.status !== 200) {
      console.error('Failed to create comment:', uploadResponse);
    }
  };

  const handleDocumentUpdate = async (
    file: File,
    fileTypeId: number,
    fileTypeName: string,
    fileName: string,
    documentStatus: number,
  ) => {
    const updateResponse = await updateDocumentType({
      file: file,
      transactionId: parseInt(id ?? '0'),
      documentTypeId: fileTypeId,
      fileToUpdate: fileName,
    });
    if (updateResponse.status !== 200) {
      console.error('Failed to create comment:', updateResponse);
    }

    if (documentStatus === rejectedStatusId) {
      await updateDocumentStatus({
        transactionId: parseInt(id ?? '0'),
        documentTypeId: fileTypeId,
        toStatusId: reSubmittedStatusId ?? 0,
      }, {
        onSuccess: async () => {
          if (fetchTransactionById.data)
          {
            const fromStatusName = statuses.find((status) => status.statusId === rejectedStatusId)?.statusName ?? '';
            const toStatusName = statuses.find((status) => status.statusId === reSubmittedStatusId)?.statusName ?? '';
            await createNotification({
              userId: userInfo.userId,
              transactionId: transactionId,
              titleTenantId: fetchTransactionById.data[0].titleTenantId,
              cityTenantId: fetchTransactionById.data[0].cityTenantId,
              transactionDocumentTypeId: fileTypeId,
              transactionDocumentTypeName: fileTypeName,
              fromStatusId: documentStatus,
              toStatusId: reSubmittedStatusId,
              fromStatusName: fromStatusName,
              toStatusName: toStatusName,
            });
          }
        },
      });
    }
  };

  const handleDownload = (
    documentName: string,
    transactionId: number,
    documentTypeId: number,
  ) => {
    handleFileDownload(documentName, transactionId, documentTypeId);
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  };

  const handleCommentSubmit = async () => {
    if (comment && comment.trim() !== '') {
      const createResponse = await addComment({
        transactionId: parseInt(id ?? '0'),
        comment: comment,
      });
      if (createResponse.status !== 200) {
        console.error('Failed to create comment:', createResponse);
      }
    }
    setComment('');
  };

  const handleRemoveDocument = async (
    transactionId: number,
    documentTypeId: number,
  ) => {
    const deleteResponse = await deleteDocumentType({
      transactionId,
      documentTypeId,
    });
    if (deleteResponse.status !== 200) {
      console.error('Failed to create comment:', deleteResponse);
    }
    window.location.reload();
  };

  const openUploadModal = () => {
    const attachedDocumentTypes = fetchDocuments.data
      ? fetchDocuments.data.map((document, index) => ({
          documentTypeId: document.fileTypeId,
          documentTypeName: document.fileType,
        }))
      : [];

    console.log('allDocumentTypes:', allDocumentTypes);
    console.log('attachedDocumentTypes:', attachedDocumentTypes);
    const filteredDocumentTypes = allDocumentTypes.filter(
      (obj2) =>
        !attachedDocumentTypes.some(
          (obj1) => obj1.documentTypeId === obj2.documentTypeId,
        ),
    );

    // console.log('filteredDocumentTypes:', filteredDocumentTypes);

    setUnattachedDocumentTypes(filteredDocumentTypes);

    setIsUploadModalOpen(true);
  };

  const closeUploadModal = () => {
    setIsUploadModalOpen(false);
  };
  if (fetchTransactionById.data && fetchTransactionById.data.length > 0) {
    let transactionId = fetchTransactionById.data[0].transactionId;
    console.log(transactionId);
  }

  const completeTransaction = async () => {
    // try {
    if (fetchTransactionById.data && fetchTransactionById.data.length > 0) {
      const transactionId = fetchTransactionById.data[0]?.transactionId;
      if (transactionId) {
        await updateTransactionStatus({
          transactionId: transactionId,
          toStatus: 'Completed',
        });
      }
    } else {
      console.error('No transaction data found');
    }
  };

  if (fetchTransactionById.isLoading) {
    console.log('Loading...');
    return <div>Loading...</div>;
  }
  if (fetchTransactionById.isFetching) {
    console.log('Fetching...');
    return <div>Fetching...</div>;
  }
  if (fetchTransactionById.isError) {
    console.log('Error...');
    return <div>Error...</div>;
  }

  return fetchTransactionById.data && fetchTransactionById.data.length > 0 ? (
    <>
      <Flex alignItems="center" paddingY={[4, 6]} paddingX={[4, 8]}>
        <RouterLink to="/title/dashboard">
          <Box
            display="flex"
            flexDirection={'row'}
            alignItems="center"
            _hover={{ bg: 'gray.200' }}
            padding={2}
            borderRadius={5}
          >
            <Icon
              as={BsArrowLeftCircle}
              style={{ width: '25px', height: '25px' }}
              color={'green.400'}
            />
            <Text
              ml={2}
              fontFamily="DM Sans Variable"
              fontSize="18px"
              lineHeight="20.83px"
              fontWeight={500}
              letterSpacing="-0.02em"
              color="green.400"
              textDecoration="underline"
            >
              Back to Dashboard
            </Text>
          </Box>
        </RouterLink>
      </Flex>
      <Flex
        paddingY={4}
        paddingX={[4, 8]}
        flexDirection="row"
        alignItems={'center'}
        justifyContent="space-between"
      >
        <Box
          // justifyContent="space-between"
          alignItems="center"
          width={['100%', '467px']}
          height="42px"
          display={'flex'}
          flexDirection={'row'}
        >
          <Heading
            fontFamily="DM Sans Variable"
            fontSize="34px"
            fontWeight="700"
            lineHeight="42px"
            letterSpacing="-0.02em"
            paddingRight={8}
          >
            Transaction Details
          </Heading>
          <Badge
            textTransform="initial"
            textAlign="center"
            width="auto"
            height="31px"
            borderRadius="50px"
            padding="5px 20px 5px 20px"
            fontFamily="DM Sans Variable"
            color={
              fetchTransactionById.data[0].currentStatus === 'New'
                ? '#3DA42F'
                : '#FFFFFF'
            }
            backgroundColor={
              fetchTransactionById.data[0].currentStatus === 'Completed'
                ? '#3DA42F'
                : fetchTransactionById.data[0].currentStatus === 'In Progress'
                ? '#0076B3'
                : fetchTransactionById.data[0].currentStatus === 'New'
                ? '#FFFFFF'
                : 'whitesmoke'
            }
            border={
              fetchTransactionById.data[0].currentStatus === 'New'
                ? '1px solid #3DA42F'
                : '2px'
            }
          >
            {fetchTransactionById.data[0].currentStatus}
          </Badge>
        </Box>
      </Flex>
      <Box
        borderWidth="1px"
        background="white"
        borderRadius="20px"
        borderColor="#D0D0D0"
        padding="20px"
        marginBottom={{ base: '20px', md: '40px' }}
        marginLeft={{ base: '0', md: '28px' }}
        marginRight={{ base: '0', md: '28px' }}
      >
        <Table variant="simple" size="sm">
          <Thead
            style={{ whiteSpace: 'nowrap' }}
            fontFamily="DM Sans Variable"
            fontWeight="700"
          >
            <Tr>
              {tableHeaders.map((header, index) => (
                <Th
                  key={index}
                  borderBottom="none"
                  textAlign="left"
                  textTransform="initial"
                  textColor="#000000"
                  fontWeight="700"
                  fontSize="14px"
                  letterSpacing="-0.02em"
                >
                  {header}
                </Th>
              ))}
              <Th borderBottom="none" textAlign="right">
                <Button
                  onClick={completeTransaction}
                  isDisabled={disableCompleteTransactionButton}
                  sx={{
                    height: '41px',
                    color: '#358535',
                    width: '223px',
                    padding: '10px 20px 10px 20px',
                    borderRadius: '50px',
                    border: ' 1px solid #3DA42F',
                    fontFamily: 'DM Sans Variable',
                    backgroundColor: 'white',
                    fontWeight: 'bold' as 'bold',
                    fontSize: '16px',
                  }}
                >
                  Complete Transaction
                </Button>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr
              border="none"
              textColor="#848181"
              fontWeight="500"
              letterSpacing="-0.02em"
              fontSize="14px"
            >
              <Td borderBottom="none" fontSize="16px">
                <Text>{fetchTransactionById.data[0].address}</Text>
                <Text>
                  {fetchTransactionById.data[0].city},{' '}
                  {fetchTransactionById.data[0].state}{' '}
                  {fetchTransactionById.data[0].zip}
                </Text>
              </Td>
              <Td border="none">
                {formatDate(fetchTransactionById.data[0].closingDate)}
              </Td>
              <Td border="none">
                {formatDate(fetchTransactionById.data[0].expiryDt ?? '')}
              </Td>
              <Td border="none">{fetchTransactionById.data[0].clientName}</Td>
              {/* <Td border="none">{fetchTransactionById.data[0].clientName}</Td> */}
              <Td border="none">{fetchTransactionById.data[0].clientType}</Td>
              <Td border="none">
                {fetchTransactionById.data[0].transactionId}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Box data-group="true" paddingY={4} paddingX={[4, 8]}>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading
            fontFamily="DM Sans Variable"
            // fontSize={['24px', '34px']}
            fontSize="24px"
            lineHeight={['42px', '42px']}
            letterSpacing="-0.02em"
          >
            Documents (
            {fetchDocuments.data && fetchDocuments.isError === false
              ? fetchDocuments.data.length
              : 0}
            )
          </Heading>
          <ButtonGroup spacing={10}>
            <Button
              bg="white"
              onClick={openUploadModal}
              color="rgb(51, 144, 37)"
              leftIcon={<Icon as={MdAdd} />}
              width="246px"
              height="44px"
              padding="10px 20px"
              borderRadius="50px"
              border="1px solid rgb(51, 144, 37)"
              isDisabled={
                fetchTransactionById?.data[0]?.currentStatus === 'Completed'
              }
              sx={{
                cursor:
                  fetchTransactionById?.data[0]?.currentStatus === 'Completed'
                    ? 'not-allowed'
                    : 'pointer',
                _hover: {
                  backgroundColor:
                    fetchTransactionById?.data[0]?.currentStatus === 'Completed'
                      ? 'gray.300'
                      : 'gray.100',
                },
              }}
            >
              <Text fontSize={['md', 'lg']} fontWeight="bold">
                Upload New Document
              </Text>
            </Button>
          </ButtonGroup>
          <UploadDocumentPopup
            isOpen={isUploadModalOpen}
            onClose={closeUploadModal}
            transactionId={parseInt(id ?? '0')}
            // fileTypeArray={attachedDocumentTypes}
            filterDocumentTypes={unattachedDocumentTypes}
          />
        </Flex>
      </Box>
      {/* {if (fetchDocuments.isFetching) return Loading...;} */}
      {fetchDocuments.data && fetchDocuments.isError === false ? (
        <>
          {fetchDocuments.data.map((document, index) => (
            <Box
              key={index}
              borderWidth="1px"
              background="white"
              borderRadius="20px"
              borderColor="#D0D0D0"
              padding="20px"
              marginBottom={{ base: '20px', md: '40px' }}
              marginLeft={{ base: '0', md: '28px' }}
              marginRight={{ base: '0', md: '28px' }}
            >
              <Flex direction="column" alignItems="stretch">
                <Box marginBottom="20px">
                  <Flex>
                    <Box flex="60%" marginRight="10px">
                      <Flex>
                        <Box
                          flex="10%"
                          marginRight="10px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Image
                            src={
                              document.currentStatus === submittedStatusId
                                ? submitted
                                : document.currentStatus === reviewedStatusId
                                ? reviewed
                                : document.currentStatus === approvedStatusId
                                ? approved
                                : document.currentStatus === rejectedStatusId
                                ? rejected
                                : submitted
                            }
                            alt="Your Image"
                            style={{ width: '50px', height: '50px' }}
                          />
                        </Box>

                        <Box flex="90%">
                          <Flex justifyContent="space-between">
                            <Flex
                              direction="column"
                              flex="1"
                              marginRight="10px"
                            >
                              <Box
                                flex="1"
                                marginBottom="10px"
                                fontWeight="bold"
                              >
                                Document Status
                              </Box>
                              <Box flex="1">{document.statusName}</Box>
                            </Flex>

                            <Flex
                              direction="column"
                              flex="1"
                              marginRight="10px"
                            >
                              <Box
                                flex="1"
                                marginBottom="10px"
                                fontWeight="bold"
                              >
                                File Type
                              </Box>
                              <Box flex="1">{document.fileType}</Box>
                            </Flex>

                            <Flex
                              direction="column"
                              flex="1"
                              marginRight="10px"
                            >
                              <Box
                                flex="1"
                                marginBottom="10px"
                                fontWeight="bold"
                              >
                                File Name
                              </Box>
                              {document.fileNames.map((filename, index) => (
                                <Box key={index} flex="1">
                                  {filename}
                                </Box>
                              ))}
                            </Flex>
                          </Flex>
                        </Box>
                      </Flex>
                    </Box>
                    <Box flex="40%"></Box>
                  </Flex>
                </Box>
                <DocumentHistoryAccordion
                  transactionId={document.transactionId}
                  documentTypeId={document.fileTypeId}
                >
                  <DocumentActions
                    onDownload={() => {
                      document.fileNames.forEach((filename) =>
                        handleDownload(
                          filename,
                          document.transactionId,
                          document.fileTypeId,
                        ),
                      );
                    }}
                    onRemoveDocument={() =>
                      handleRemoveDocument(
                        document.transactionId,
                        document.fileTypeId,
                      )
                    }
                    onUploadNewFile={() =>
                      openFileUploadModal(document.fileTypeId)
                    }
                    isRemovedDisabled={
                      document.currentStatus === reviewedStatusId ||
                      document.currentStatus === approvedStatusId
                    }
                    isUploadNewFileDisabled={
                      document.currentStatus === reviewedStatusId ||
                      document.currentStatus === approvedStatusId
                    }
                  />
                  <UploadNewFile
                    isOpen={
                      isUploadNewFile &&
                      document.fileTypeId === modalSelectedDocumentId
                    }
                    onClose={closeFileUploadModal}
                    handleUpload={
                      !(document.fileTypeId === otherDocTypeId)
                        ? (file: File) =>
                            handleDocumentUpdate(
                              file,
                              document.fileTypeId,
                              document.fileType,
                              document.fileNames[0],
                              document.currentStatus,
                            )
                        : (file: File) =>
                            handleDocumentUpload(file, document.fileTypeId)
                    }
                  />
                </DocumentHistoryAccordion>
              </Flex>
            </Box>
          ))}
        </>
      ) : (
        <Text textAlign={'center'}>No documents found</Text>
      )}
      <Box data-group="true" paddingY={4} paddingX={[4, 8]}>
        <Heading
          fontFamily="DM Sans Variable"
          // fontSize={['24px', '34px']}
          fontSize="24px"
          lineHeight={['42px', '42px']}
          letterSpacing="-0.02em"
        >
          Comments
        </Heading>
      </Box>

      <CommentSection
        // transactionId={id || ''}
        handleCommentChange={handleCommentChange}
        handleCommentSubmit={handleCommentSubmit}
        setComment={setComment}
        comment={comment}
        createdComments={CommentsById.data ?? []}
        transactionData={fetchTransactionById.data}
      />
    </>
  ) : (
    <div>Loading...</div>
  );
};

export default ViewTransactionDetail;
