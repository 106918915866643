import { useFetchDocumentStatuses } from "../hooks/useStaticData";
import { DocumentStatus } from "../types/DocumentStatus";

export const DocumentStatusIds = () => {
    const fetchStatuses = useFetchDocumentStatuses();

    const rejectedStatusId = fetchStatuses.data?.find(
        (status) => status.statusName === 'Rejected',
    )?.statusId as number;
    const approvedStatusId = fetchStatuses.data?.find(
        (status) => status.statusName === 'Approved',
    )?.statusId as number;
    const reviewedStatusId = fetchStatuses.data?.find(
        (status) => status.statusName === 'Reviewed',
    )?.statusId as number;
    const submittedStatusId = fetchStatuses.data?.find(
        (status) => status.statusName === 'Submitted',
    )?.statusId as number;
    const reSubmittedStatusId = fetchStatuses.data?.find(
        (status) => status.statusName === 'Re-Submitted',
    )?.statusId as number;
    const statuses = fetchStatuses.data as DocumentStatus[];

    return { rejectedStatusId, approvedStatusId, reviewedStatusId, submittedStatusId, reSubmittedStatusId, statuses };
};

// const DocumentStatusName = (statusId: number) => {
//     const statusName = (statusId === DocumentStatusIds().rejectedStatusId
//         ? 'Rejected'
//         : statusId === DocumentStatusIds().approvedStatusId
//             ? 'Approved'
//             : statusId === DocumentStatusIds().reviewedStatusId
//                 ? 'Reviewed'
//                 : statusId === DocumentStatusIds().submittedStatusId
//                     ? 'Submitted'
//                     : statusId === DocumentStatusIds().reSubmittedStatusId
//                         ? 'Re-Submitted'
//                         : '');

//     return statusName as string;
// };