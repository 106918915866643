
const viewTransaction = {
    height: "41px",
    color: "#358535",
    width: "223px",
    padding: "10px 20px 10px 20px",
    borderRadius: "50px",
    border: " 1px solid #3DA42F",
    fontFamily: "DM Sans Variable",
    backgroundColor: "white",
    fontWeight: "bold" as "bold",
    fontSize: "16px",
  };

  export default viewTransaction;