import React, { useState, ChangeEvent } from 'react';
import {
  Box,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Select,
  Icon,
  Text,
  Button,
  Flex,
  FormErrorMessage,
} from '@chakra-ui/react';
import { MdClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { getCurrentDate } from '../../utils/DateUtils';
import { useCreateTransaction } from '../../hooks/useTransaction';
import { useUploadDocumentType } from '../../hooks/useFiles';
import { useFetchStaticData } from '../../hooks/useStaticData';
import { City } from '../../types/City';
import { useUserContext } from '../../Context/UserContext';
import { Dropzone } from '../../components/Dropzone';

interface FormData {
  address: string;
  cityId: string;
  cityName: string;
  state: string;
  zip: string;
  clientName: string;
  Email: string;
  clientType: string;
  selectedDate: string;
}

interface TransactionPersona {
  personaId: number;
  personaName: string;
}

interface DocumentType {
  documentTypeId: number;
  documentTypeName: string;
}

interface DocumentData {
  documentTypeId: number;
  documentTypeName: string;
  fileData: File;
}

const CreateTransaction = () => {
  const userInfo = useUserContext();
  const titleTenantId = userInfo.tenantId;

  const [uploadFiles, setUploadFiles] = useState<DocumentData[]>([]);
  const [currentDocumentType, setCurrentDocumentType] =
    useState<DocumentType | null>(null);
  const history = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    address: '',
    cityId: '',
    cityName: '',
    state: 'MI',
    zip: '',
    clientName: '',
    Email: '',
    clientType: '',
    selectedDate: '',
  });
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const styleInputFields = {
    width: '464px',
    height: '50px',
    borderRadius: '16px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #A3AED0',
  };
  const [stateNames] = useState<string[]>(['MI']);
  const { mutateAsync: addTransaction } = useCreateTransaction();
  const { mutateAsync: uploadDocumentType } = useUploadDocumentType();
  const staticData = useFetchStaticData();

  const cityNames = (staticData.data?.cities as City[]) ?? [];
  const transactionPersonas =
    (staticData.data?.transactionPersonas as TransactionPersona[]) ?? [];
  const documentTypes =
    (staticData.data?.documentTypes as DocumentType[]) ?? [];

  const otherDocumentTypeId = documentTypes.find(
    (documentType) =>
      documentType.documentTypeName.trim().toLowerCase() === 'other',
  )?.documentTypeId;

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { options, value } = e.target;
    const selectedIndex = options.selectedIndex;
    const fileTypeName = options[selectedIndex].getAttribute('file-type-name');

    if (fileTypeName) {
      setCurrentDocumentType({
        documentTypeId: parseInt(value),
        documentTypeName:
          options[selectedIndex].getAttribute('file-type-name')!,
      });
    } else {
      setCurrentDocumentType(null);
    }
  };

  const handleFileUpload = (
    e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>,
  ) => {
    let files: FileList | null = null;
    if (e.type === 'change') {
      files = (e as React.ChangeEvent<HTMLInputElement>).target.files;
    } else if (e.type === 'drop') {
      files = (e as React.DragEvent<HTMLDivElement>).dataTransfer.files;
    }
    if (files && files.length > 0) {
      const file = files[0];

      if (currentDocumentType === null) {
        setErrorFields([...errorFields, 'filetype']);
      }

      if (file && currentDocumentType !== null) {
        setUploadFiles([
          ...uploadFiles,
          {
            documentTypeId: currentDocumentType.documentTypeId,
            documentTypeName: currentDocumentType.documentTypeName,
            fileData: file,
          },
        ]);
        setCurrentDocumentType(null);
        setErrorFields(errorFields.filter((field) => field !== 'filetype'));
      }
    }
  };

  const removeUploadedFile = (e: React.MouseEvent<HTMLButtonElement> | null) => {
    if (e === null) {
      return;
    }

    let fileId = parseInt(e.currentTarget.getAttribute('file-id')!);
    let fileName = e.currentTarget.getAttribute('file-name');

    setCurrentDocumentType(null);

    setUploadFiles(
      uploadFiles.filter(
        (UploadFiles) =>
          !(
            UploadFiles.documentTypeId === fileId &&
            UploadFiles.fileData.name === fileName
          ),
      ),
    );

    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const currentDate = new Date().toISOString().split('T')[0];

    if ((e.currentTarget as HTMLInputElement).value > currentDate) {
      setErrorFields([...errorFields, 'selectedDate']); // Set date error state to true
    } else {
      setErrorFields(errorFields.filter((field) => field !== 'selectedDate')); // Clear date error state
    }
  };

  const handleSubmit = async () => {
    const requiredFields: (keyof FormData)[] = [
      'address',
      'cityId',
      'state',
      'zip',
      'clientName',
      'clientType',
      'selectedDate',
    ];
    const errors = requiredFields.filter((field) => !formData[field]);

    // setErrorFields([...errorFields, ...errors]);
    // setErrorFields(errors);

    if (uploadFiles.length === 0) {
      setErrorFields([...errors, 'filetype']);
    } else {
      setErrorFields(errors);
    }

    if (errors.length === 0) {
      try {
        const selectedCityName = cityNames.find(
          (obj) => obj.cityId === parseInt(formData.cityId),
        );
        const createResponse = await addTransaction({
          titleTenantId: titleTenantId,
          cityTenantId: selectedCityName?.tenantId ?? null,
          cityId: parseInt(formData.cityId),
          persona: parseInt(formData.clientType),
          address: formData.address,
          city: selectedCityName?.cityName ?? '',
          state: formData.state,
          zip: formData.zip,
          closingDate: formData.selectedDate,
          clientName: formData.clientName,
          emailAddress: formData.Email,
          receiveNotifications: 0,
          // currentComments: '',
        });
        // console.log(response1.data, 'response data from the first post call');

        if (uploadFiles.length > 0) {
          uploadFiles.map(async (uploadFile, index) => {
            const uploadResponse = await uploadDocumentType({
              file: uploadFile.fileData,
              transactionId: createResponse.data.transactionId,
              documentTypeId: uploadFile.documentTypeId,
            });
            if (uploadResponse.status !== 200) {
              console.error('uploadResponse: ', uploadResponse);
            }
          });
        }
        history('/');
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        top="180px"
        background={'#F4F7FE'}
        py={12}
      >
        <Stack spacing={4}>
          <FormControl style={{ width: '446px', height: '42px' }}>
            <FormLabel
              fontSize="34px"
              fontWeight="bold"
              fontFamily="DM Sans Variable"
              lineHeight="42px"
              textAlign="center"
            >
              Create New Transaction
            </FormLabel>
          </FormControl>
          <FormControl isRequired isInvalid={errorFields.includes('state')}>
            <FormLabel fontSize="12px">Select State</FormLabel>
            <Select
              style={styleInputFields}
              name="state"
              disabled
              value={formData.state || stateNames[0]}
              onChange={handleInputChange}
              placeholder="Select State"
              borderColor={
                errorFields.includes('state') ? 'red.500' : 'inherit'
              }
            >
              {stateNames.map((ele, index) => (
                <option key={index} value={ele}>
                  {ele}
                </option>
              ))}
            </Select>
            <FormErrorMessage>Select State</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={errorFields.includes('city')}>
            <FormLabel fontSize="12px">Select City</FormLabel>
            <Select
              style={styleInputFields}
              name="cityId"
              value={formData.cityId}
              onChange={handleInputChange}
              placeholder="Select City"
              borderColor={errorFields.includes('city') ? 'red.500' : 'inherit'}
            >
              {cityNames.map((ele, index) => (
                <option key={ele.cityId} value={ele.cityId}>
                  {ele.cityName}
                </option>
              ))}
            </Select>
            <FormErrorMessage>Select City</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={errorFields.includes('address')}>
            <FormLabel fontSize="12px">Address</FormLabel>
            <Input
              style={styleInputFields}
              placeholder="Enter Address"
              type="text"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              borderColor={
                errorFields.includes('address') ? 'red.500' : 'inherit'
              }
            />
            <FormErrorMessage>Enter Address</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={errorFields.includes('zip')}>
            <FormLabel fontSize="12px">Zipcode</FormLabel>
            <Input
              style={styleInputFields}
              placeholder="Enter Zipcode"
              type="text"
              name="zip"
              value={formData.zip}
              onChange={handleInputChange}
              borderColor={errorFields.includes('zip') ? 'red.500' : 'inherit'}
            />
            <FormErrorMessage>Enter Zipcode</FormErrorMessage>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={errorFields.includes('selectedDate')}
          >
            <FormLabel fontSize="12px">Closing Date</FormLabel>
            <Input
              type="date"
              name="selectedDate"
              value={formData.selectedDate}
              onChange={handleDateChange}
              onKeyUp={handleKeyDown}
              style={styleInputFields}
              max={getCurrentDate()}
            />
            <FormErrorMessage>Enter Closing Date</FormErrorMessage>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={errorFields.includes('clientName')}
          >
            <FormLabel fontSize="12px">Client Name</FormLabel>
            <Input
              style={styleInputFields}
              placeholder="Enter Client Name"
              type="text"
              name="clientName"
              value={formData.clientName}
              onChange={handleInputChange}
              borderColor={
                errorFields.includes('clientName') ? 'red.500' : 'inherit'
              }
            />
            <FormErrorMessage>Enter Client Name</FormErrorMessage>
          </FormControl>
          <FormControl>
            <FormLabel fontSize="12px">Client Email Address</FormLabel>
            <Input
              style={styleInputFields}
              placeholder="Enter Client Email Address"
              name="Email"
              value={formData.Email}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl
            isRequired
            isInvalid={errorFields.includes('clientType')}
          >
            <FormLabel fontSize="12px">Client Type</FormLabel>
            <Select
              style={styleInputFields}
              name="clientType"
              value={formData.clientType}
              onChange={handleInputChange}
              placeholder="Select Client Type"
            >
              {transactionPersonas.map((ele, index) => (
                <option key={ele.personaId} value={ele.personaId}>
                  {ele.personaName}
                </option>
              ))}
            </Select>
            <FormErrorMessage>Select Client Type</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={errorFields.includes('filetype')}>
            <FormLabel fontSize="12px">Form</FormLabel>
            <Select
              style={styleInputFields}
              name="filetype"
              value={
                currentDocumentType === null
                  ? ''
                  : currentDocumentType.documentTypeId
              }
              onChange={handleFileTypeChange}
              placeholder="Select Form Type"
            >
              {documentTypes.map((ele, index) => (
                <option
                  key={index}
                  value={ele.documentTypeId}
                  file-type-name={ele.documentTypeName}
                  disabled={
                    uploadFiles.some(
                      (uploadFile) =>
                        uploadFile.documentTypeId === ele.documentTypeId,
                    ) && ele.documentTypeId !== otherDocumentTypeId
                  }
                >
                  {ele.documentTypeName}
                </option>
              ))}
            </Select>
            <FormErrorMessage>Select Form Type</FormErrorMessage>
          </FormControl>
          <Dropzone
            onFileChange={(e) => handleFileUpload(e)}
            onRemoveFile={() => removeUploadedFile(null)}
            dropFile={null}
          />
          {uploadFiles.map((ele, index) => (
            <Flex
              key={index}
              border="1px solid #D0D0D0"
              borderRadius="4px"
              padding="5px 20px 5px 20px"
              minH="49px"
              justify="space-between"
            >
              <Flex direction="column" gap="5px" minH="39px" maxW="350px">
                <Text
                  fontWeight="500"
                  fontFamily="DM Sans Variable"
                  color="#848181"
                  fontSize="14px"
                  lineHeight="14px"
                  letterSpacing="-0.02em"
                >
                  {ele.documentTypeName}
                </Text>
                <Text
                  fontSize="20px"
                  fontWeight="500"
                  fontFamily="DM Sans Variable"
                  color="#000000"
                  lineHeight="20px"
                  letterSpacing="-0.02em"
                >
                  {ele.fileData?.name}
                </Text>
              </Flex>
              <Box alignContent="Center">
                <Button
                  rightIcon={<Icon as={MdClose} boxSize="25px" />}
                  variant="ghost"
                  colorScheme="black"
                  // size="lg"
                  left="25px"
                  file-id={ele.documentTypeId}
                  file-name={ele.fileData.name}
                  file-type={ele.documentTypeName}
                  onClick={removeUploadedFile}
                />
              </Box>
            </Flex>
          ))}
          <Box textAlign="center" margin="auto">
            <Button
              backgroundColor="#3DA42F"
              padding="15px 50px 15px 50px"
              borderRadius="50px"
              height="51px"
              width="464px"
              color="#FFFFFF"
              onClick={handleSubmit}
            >
              Submit Documents
            </Button>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default CreateTransaction;
