import { AuthProviderProps } from "react-oidc-context";

export const oidcConfig : AuthProviderProps = {
    authority: process.env.REACT_APP_OIDC_AUTHORITY,
    client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URI,
    post_logout_redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URI,
    // userStore: new WebStorageStateStore({ store: window.localStorage }),
    onSigninCallback: () => {
      window.history.replaceState({}, document.title, "/");
      window.location.href = "/";
    },
    // accessTokenExpiringNotificationTimeInSeconds: 60,
    // checkSessionIntervalInSeconds: 60,
    onSignoutCallback: () => {
      window.history.replaceState({}, document.title, "/");
      window.location.href = "/";
    },
  };