import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { createCommentById, fetchCommentsById } from "../services/api/commentService";

export const useFetchComments = (id: number) => {
    return useQuery({
        queryKey: ["getCommentsById", { id }],
        queryFn: () => fetchCommentsById(id),
    });
};

export const useCreateComment = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: createCommentById,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["getCommentsById"] });
        },
    });
};