import { extendTheme } from '@chakra-ui/react'
import '@fontsource-variable/dm-sans';

const fonts = {
  DM: `'DM Sans Variable', sans-serif`,
  heading: `'DM Sans Variable', sans-serif`,
  body: `'DM Sans Variable', sans-serif`,

};

const theme = extendTheme({ fonts });

export default theme;