import React, { ReactNode } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Header from './Header';
import Footer from './Footer';

const Layout: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Flex minHeight="100vh" direction={'column'}>
      <Box color="white" position="sticky" top="0" zIndex="sticky">
        <Header />
      </Box>
      <Box
        flexGrow={1}
        px={{ base: '30px', md: 'inherit' }}
        py={{ base: '30px', md: 'inherit' }}
        background={'#F4F7FE'}
        margin={'0px'}
      >
        {children}
      </Box>
      <Box color="white">
        <Footer />
      </Box>
    </Flex>
  );
};

export default Layout;
